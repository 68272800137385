.blur-in {
	-webkit-animation: blur 2s forwards;
	-moz-animation: blur 2s forwards;
	-o-animation: blur 2s forwards;
	animation: blur 2s forwards;
}

.blur-out {
	-webkit-animation: blur-out 2s forwards;
	-moz-animation: blur-out 2s forwards;
	-o-animation: blur-out 2s forwards;
	animation: blur-out 2s forwards;
}

// blur is currently turned off - remove class BlurRenderer from .fadeElement.js to activate
.blur__filter {
	-webkit-transition: ease-in-out all 4.25s;
	transition: ease-in-out all 4.25s;
	transition-timing-function: ease-in-out;
	filter: blur(5px);
	-o-filter: blur(5px);
	-ms-filter: blur(5px);
	-moz-filter: blur(5px);
	-webkit-filter: blur(5px);
}

// blur is currently turned off - remove class BlurRenderer from .fadeElement.js to activate
.blur__filter__on {
	-webkit-transition: ease-in-out all 4.25s;
	transition: ease-in-out all 4.25s;
	transition-timing-function: ease-in-out;
	filter: blur(0px);
	-o-filter: blur(0px);
	-ms-filter: blur(0px);
	-moz-filter: blur(0px);
	-webkit-filter: blur(0px);
}

//SHUFFLE UNDERLINE ANIMATION
.shuffle__underline:after {
	border-radius: 1em;
	border-top: .1em solid #00584f;
	content: "";
	position: absolute;
	right: 100%;
	bottom: -0.10em;
	left: 0;
	transition: right 2s cubic-bezier(0, .5, 0, 1);
	border-color: #00584f;
}

.shuffle__underline:hover:after {
	animation: anchor-underline 2s cubic-bezier(0, .5, 0, 1) infinite;
	border-color: #00584f;
}

//SHUFFLE UNDERLINE FOOTER ANIMATION
.shuffle__underline-footer:after {
	border-radius: 1em;
	border-top: .1em solid white;
	content: "";
	position: absolute;
	right: 100%;
	bottom: -0.10em;
	left: 0;
	transition: right 2s cubic-bezier(0, .5, 0, 1);
	border-color: white;
}

.shuffle__underline-footer:hover:after {
	animation: anchor-underline 2s cubic-bezier(0, .5, 0, 1) infinite;
	border-color: white;
}

// END

// SHUFFLE UNDERLINE FOR FOOTER SOCIAL
/*
.social__links a:after {
    border-radius: 1em;
    border-top: .1em solid rgba(255, 255, 255, 0.500);
    content: "";
    position: absolute;
        right: 100%;
        bottom: -0.10em;
        left: 0;
    transition: right 2s cubic-bezier(0,.5,0,1);
}

.social__links a:hover:after {
    animation: anchor-underline 2s cubic-bezier(0,.5,0,1) infinite;
    border-color: rgba(255, 255, 255, 0.500);
}
*/

// KEYFRAME ANIMATION FOR UNDERLINE
@keyframes anchor-underline {
	0%,
	10% {
		left: 0;
		right: 100%;
	}
	40%,
	60% {
		left: 0;
		right: 0;
	}
	90%,
	100% {
		left: 100%;
		right: 0;
	}
}

//END

//BUTTON - CTA MAIN

// HEADER BUTTON
span.btn__main-header-call {
	width: 50%;
	padding: 0.75em 1em 0.75em 1em;
	cursor: pointer;
	border: 1px solid white;
	display: flex;
	justify-content: center;
	align-items: center;
	background: linear-gradient(to right, white 50%, transparent 50%);
	background-size: 200% 100%;
	background-position: right bottom;
	transition: all .5s ease-out;
	position: relative;
	top: 20px;
	@media screen and (max-width: 1530px) {
		width: 65%;
	}
	@media screen and (max-width: 1133px) {
		width: 80%;
	}
}

span.btn__main-header-call a {
	text-decoration: none;
	text-transform: uppercase;
	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: 1px;
	color: white;
	font-family: "Poppins", sans-serif;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

span.btn__main-header-call svg {
	position: relative;
	width: 0;
	right: 0px;
	right: 0rem;
	opacity: 0;
	top: 0.5rem;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	transition: all 300ms ease-out;
	will-change: right, opacity;
}
span.btn__main-header-call svg * {
	stroke-width: 5;
	stroke: transparent;
}
span.btn__main-header-call:hover svg {
	opacity: 1;
	right: -1rem;
	width: 1em;
	top: 0.5rem;
}

span.btn__main-header-call:hover {
	padding-right: 40px;
	background-position: left bottom;
	border: 1px solid #284346;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

span.btn__main-header-call:hover a {
	color: #284346;
}

// ARTICLE BUTTON
span.main__article-button-desktop {
	width: 25%;
	padding: 0.75em 1em 0.75em 1em;
	cursor: pointer;
	border: 1px solid #284346;
	display: flex;
	justify-content: center;
	align-items: center;
	background: linear-gradient(to right, #284346 50%, transparent 50%);
	background-size: 200% 100%;
	background-position: right bottom;
	transition: all .5s ease-out;
	position: relative;
	margin-top: 3.5rem;
	@media screen and (min-width: 1024px) {
		width: 40%;
	}
	@media screen and (min-width: 1315px) {
		width: 25%;
	}
}

span.main__article-button-desktop a {
	text-decoration: none;
	text-transform: uppercase;
	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: 1px;
	color: #284346;
	font-family: "Poppins", sans-serif;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

span.main__article-button-desktop svg {
	position: relative;
	width: 0;
	right: 0px;
	right: 0rem;
	opacity: 0;
	top: 0.5rem;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	transition: all 300ms ease-out;
	will-change: right, opacity;
}
span.main__article-button-desktop svg * {
	stroke-width: 5;
	stroke: transparent;
}
span.main__article-button-desktop:hover svg {
	opacity: 1;
	right: -1rem;
	width: 1em;
	top: 0.5rem;
}

span.main__article-button-desktop:hover {
	padding-right: 40px;
	background-position: left bottom;
	border: 1px solid white;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

span.main__article-button-desktop:hover a {
	color: white;
}

// MOUNTAIN BUTTON 
span.main__mtn-button-desktop {
	width: 50%;
	padding: 0.75em 1em 0.75em 1em;
	cursor: pointer;
	border: 1px solid #284346;
	display: flex;
	justify-content: center;
	align-items: center;
	background: linear-gradient(to right, #284346 50%, transparent 50%);
	background-size: 200% 100%;
	background-position: right bottom;
	transition: all .5s ease-out;
	position: relative;
	margin-top: 3.5rem;
	@media screen and (max-width: 1440px) {
		width: 70%;
	}
	@media screen and (max-width: 1310px) {
		width: 65%;
	}
	@media screen and (max-width: 1270px) {
		width: 70%;
	}
	@media screen and (max-width: 1160px) {
		width: 75%;
	}
	@media screen and (max-width: 1092px) {
		width: 80%;
	}
	@media screen and (max-width: 1024px) {
		width: 50%;
	}
	@media screen and (max-width: 855px) {
		width: 60%;
	}
	@media screen and (max-width: 700px) {
		width: 70%;
	}
	@media screen and (max-width: 586px) {
		width: 90%;
	}
	@media screen and (max-width: 586px) {
		width: 100%;
	}
}

span.main__mtn-button-desktop a {
	text-decoration: none;
	text-transform: uppercase;
	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: 1px;
	color: #284346;
	font-family: "Poppins", sans-serif;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
	@media screen and (max-width: 410px) {
		font-size: 0.55rem;
	}
}

span.main__mtn-button-desktop svg {
	position: relative;
	width: 0;
	right: 0px;
	right: 0rem;
	opacity: 0;
	top: 0.5rem;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	transition: all 300ms ease-out;
	will-change: right, opacity;
}
span.main__mtn-button-desktop svg * {
	stroke-width: 5;
	stroke: transparent;
}
span.main__mtn-button-desktop:hover svg {
	opacity: 1;
	right: -1rem;
	width: 1em;
	top: 0.5rem;
}

span.main__mtn-button-desktop:hover {
	padding-right: 40px;
	background-position: left bottom;
	border: 1px solid white;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

span.main__mtn-button-desktop:hover a {
	color: white;
}

// GUIDE BUTTON
span.main__strat-button {
	width: 40%;
	padding: 0.75em 1em 0.75em 1em;
	cursor: pointer;
	border: 1px solid #284346;
	display: flex;
	justify-content: center;
	align-items: center;
	background: linear-gradient(to right, #284346 50%, transparent 50%);
	background-size: 200% 100%;
	background-position: right bottom;
	transition: all .5s ease-out;
	position: relative;
	top: 20px;
	@media screen and (max-width: 1024px) {
		width: 60%;
	}
}

span.main__strat-button a {
	text-decoration: none;
	text-transform: uppercase;
	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: 1px;
	color: #284346;
	font-family: "Poppins", sans-serif;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

span.main__strat-button svg {
	position: relative;
	width: 0;
	right: 0px;
	right: 0rem;
	opacity: 0;
	top: 0.5rem;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	transition: all 300ms ease-out;
	will-change: right, opacity;
}
span.main__strat-button svg * {
	stroke-width: 5;
	stroke: transparent;
}
span.main__strat-button:hover svg {
	opacity: 1;
	right: -1rem;
	width: 1em;
	top: 0.5rem;
}

span.main__strat-button:hover {
	padding-right: 40px;
	background-position: left bottom;
	border: 1px solid white;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

span.main__strat-button:hover a {
	color: white;
}

// compare btn__sub and recreate for footer and arrow

span.btn__main {
	padding: 0.75em 1em 0.75em 1em;
	cursor: pointer;
	border: 1px solid white;
	display: flex;
	justify-content: center;
	align-items: center;
	background: linear-gradient(to right, #3ea89a 50%, transparent 50%);
	background-size: 200% 100%;
	background-position: right bottom;
	transition: all .5s ease-out;
}

span.btn__main a {
	text-decoration: none;
	text-transform: uppercase;
	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: 1px;
	color: white;
	font-family: "Poppins", sans-serif;
}

span.btn__main svg {
	position: relative;
	width: 0;
	right: 0px;
	right: 0rem;
	opacity: 0;
	top: 0rem;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	transition: all 300ms ease-out;
	will-change: right, opacity;
}
span.btn__main svg * {
	stroke-width: 5;
	stroke: transparent;
}
span.btn__main:hover svg {
	opacity: 1;
	right: -1rem;
	width: 1em;
	top: 0.5rem;
}

span.btn__main:hover {
	padding-right: 40px;
	background-position: left bottom;
	border: 1px solid #3ea89a;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

// APPROACH CTA
span.btn__main-home-approach {
	padding: 0.75em 1em 0.75em 1em;
	cursor: pointer;
	border: 1px solid #284346;
	display: flex;
	justify-content: center;
	align-items: center;
	background: linear-gradient(to right, #284346 50%, transparent 50%);
	background-size: 200% 100%;
	background-position: right bottom;
	transition: all .5s ease-out;
}

span.btn__main-home-approach a {
	text-decoration: none;
	text-transform: uppercase;
	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: 1px;
	color: #284346;
	font-family: "Poppins", sans-serif;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

span.btn__main-home-approach svg {
	position: relative;
	width: 0;
	right: 0px;
	right: 0rem;
	opacity: 0;
	top: 0.5rem;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	transition: all 300ms ease-out;
	will-change: right, opacity;
}
span.btn__main-home-approach svg * {
	stroke-width: 5;
	stroke: transparent;
}
span.btn__main-home-approach:hover svg {
	opacity: 1;
	right: -1rem;
	width: 1em;
	top: 0.5rem;
}

span.btn__main-home-approach:hover {
	padding-right: 40px;
	background-position: left bottom;
	border: 1px solid #284346;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

span.btn__main-home-approach:hover a {
	color: white;
}

// END

//BUTTON - SUB BUTTON

// fixed for font color hover

span.btn__sub {
	padding: 1.1rem;
	cursor: pointer;
	margin-left: 0.5rem;
	border: 1px solid white;
	background: linear-gradient(to right, #ffffff 50%, transparent 50%);
	background-size: 200% 100%;
	background-position: right bottom;
	transition: all .5s ease-out;
	position: relative;
}

span.btn__sub input[type=submit] {
	text-decoration: none;
	text-transform: uppercase;
	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: 1px;
	color: #ffffff;
	background-color: transparent;
	font-family: "Poppins", sans-serif;
	transition: all .5s ease-out;
	cursor: pointer;
}

span.btn__sub svg {
	position: relative;
	width: 0;
	right: 0px;
	right: 0rem;
	opacity: 0;
	top: 0.40rem;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	transition: all 300ms ease-out;
	will-change: right, opacity;
}

span.btn__sub svg * {
	stroke-width: 5;
	stroke: transparent;
}

//FIX ARROW JUMP

span.btn__sub:hover svg {
	opacity: 1;
	right: -0.75rem;
	width: 0.75em;
	top: 0.40rem;
}

span.btn__sub:hover {
	padding-right: 30px;
	background-position: left bottom;
	border: 1px solid #3ea89a;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

span.btn__sub:hover input[type=submit] {
	color: #3ea89a;
}

.scroll__lock {
	overflow-y: hidden;
}

// END

.section__map {
	width: 100%;
	height: 35px;
	background: #2a454a;
	display: flex;
	justify-content: center;
	align-items: center;
	@media only screen and (max-width: 840px) and (orientation: landscape) {
		padding-top: 2rem;
	}
}

.section__map-content-container {
	width: 80%;
	height: 100%;
	display: flex;
	align-items: center;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

.section__map-line {
	height: 1px;
	width: 80%;
	background: rgba(255, 255, 255, 0.3);
}

.section__map-text {
	padding-left: 1rem;
	@media (min-width: 320px) and (max-width: 599px) {
		padding: 0.5rem;
	}
}

.section__map-text h4 {
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
	color: rgba(255, 255, 255, 0.3);
	text-transform: uppercase;
	font-size: 0.75rem;
	letter-spacing: 0.1rem;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 0.5rem;
	}
}

//approach section map

.section__map-approach {
	width: 100%;
	height: 35px;
	background: white;
	display: flex;
	justify-content: center;
	align-items: center;
}

.section__map-approach-content-container {
	width: 80%;
	height: 100%;
	display: flex;
	align-items: center;
}

.section__map-approach-line {
	height: 1px;
	width: 90%;
	background: rgba(0, 0, 0, 0.3);
}

.section__map-approach-text {
	padding-left: 1rem;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

.section__map-approach-text h4 {
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
	color: rgba(0, 0, 0, 0.3);
	text-transform: uppercase;
	font-size: 0.75rem;
	letter-spacing: 0.1rem;
}

.social__share {
	display: flex;
	width: auto;
	height: 100%;
	position: fixed;
	right: 0;
	top: 0;
	pointer-events: none;
	align-content: center;
	align-items: center;
	@media screen and (max-width: 1024px) {
		display: none;
	}
}

.social__share ul {
	display: flex;
	flex-direction: column;
	padding: 14px 12px;
	border-top: 1px solid #f0f4f6;
	border-bottom: 1px solid #f0f4f6;
	border-left: 1px solid #f0f4f6;
	background: #fff;
	pointer-events: all;
	align-content: center;
	align-items: center;
}

.social__share ul li {
	margin-bottom: 10px;
	width: 30px;
	height: 30px;
	cursor: pointer;
	align-content: center;
	align-items: center;
	text-align: center;
	text-decoration: none;
	list-style: none;
}

.social-media-share-btn {
	cursor: pointer;
	text-align: center;
	pointer-events: all;
}

.social-svg a img {
	width: 25px;
	height: 25px;
}

.social-svg a img:hover {
	filter: grayscale(100%);
	opacity: 0.4;
	transform: scale(1.2);
}

.social-svg span.email img {
	width: 25px;
	height: 25px;
}

.social-svg span.email img:hover {
	filter: grayscale(100%);
	opacity: 0.4;
	transform: scale(1.2);
}

//Share Form

.EmailShareWindow {
	position: fixed;
	left: 0;
	top: 20%;
	width: 100%;
	z-index: 200;
	display: none;
}

.EmailShareWindow--formContainer {
	max-height: 800px;
	left: 50%;
	margin-left: -390px;
	width: 650px;
	padding: 0 40px;
	overflow: hidden;
	position: absolute;
    z-index: 200;
    border: 1px solid lightgrey;
    background-color: white;
}

.EmailShareForm-menuCloseButtonContainer {
	padding-top: 25px;
	margin-right: -15px;
	text-align: right;
	height: 50px;
}

.EmailShareForm-menuCloseButton {
	-webkit-transition: all .30s;
	-moz-transition:    all .30s;
	-o-transition:      all .30s;
	-ms-transition:     all .30s;   
	transition:         all .30s;
	cursor: pointer;
}

.EmailShareForm-menuCloseButton svg:hover {
	width: 25px;
	height: 25px;
	-webkit-transition: all .30s;
	-moz-transition:    all .30s;
	-o-transition:      all .30s;
	-ms-transition:     all .30s;   
	transition:         all .30s;
}

.EmailShareForm {
	text-align: left;
	margin-bottom: 30px;
	margin-top: -30px;
}

.EmailShareForm .EmailShareForm-Title {
	font-family: "Poppins", sans-serif;
	font-weight: 500;
    font-size: 16px;
    text-transform: none;
    line-height: 26px;
	letter-spacing: -.5px;
	color: #284346;
}

.EmailShareForm-wrappingInputSection {
	display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 16px;
    line-height: 26px;
    color: #262c30;
    line-height: 1.35;
    margin-top: 20px;
}

.EmailShareFormTextField.TextField {
    padding-bottom: 5px;
    width: 100%;
}

.TextField--medium {
    max-width: 432px;
}

.TextField {
    margin-right: 40px;
	display: block;
}

.EmailShareFormTextField .TextField-input {
    margin-right: 15px;
    padding: 4px;
    display: inline-block;
    width: 470px;
    position: relative;
    font-size: 10px;
    line-height: 32px;
	letter-spacing: -.5px;
	color: black;
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
    border: 1px solid lightgrey;
    border-bottom: 2px solid #3ea89a;
    transition: border .3s;
}

.EmailShareFormTextField .TextField-input::placeholder {
	color: light;
	font-size: 12px;
	font-weight: 300;
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
}

.TextField-Labels {
    width: 100%;
}

.EmailShareFormTextField .TextField-inputLabel {
    display: none;
}

.TextField-requiredLabel {
    padding-left: 5px;
	color: #284346;
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
	padding-top: 1rem;
	font-weight: bold;
	font-size: 10px;
}

.TextField-fieldErrorMessage {
    color: red;
}

.EmailShareFormTextField.TextField {
    padding-bottom: 5px;
    width: 100%;
}

.TextField--large {
    max-width: 905px;
    flex-basis: 100%;
}

.TextField {
    margin-right: 40px;
	display: block;
}

.EmailShareFormTextField.TextField--large .TextField-input {
    width: 100%;
	height: 100px;
	color: black;
	font-size: 14px;
	font-family: "neue-haas-grotesk-text-pro", sans-serif;

}

.EmailShareFormTextField .TextField-input {
    margin-right: 15px;
    padding: 4px;
    display: inline-block;
    width: 470px;
    position: relative;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -.5px;
    border: 1px solid #d8dde0;
    border-bottom: 2px solid #3ea89a;
    -webkit-transition: border .3s;
    transition: border .3s;
}

.TextField--large .TextField-input {
    height: 150px;
    border-top: 1px solid #d8dde0;
    border-left: 1px solid #d8dde0;
    border-right: 1px solid #d8dde0;
}

// SHARE EMAIL BUTTON
span.EmailShare--button {
	width: 22%;
	padding: 0.75em 1em 0.75em 1em;
	cursor: pointer;
	border: 1px solid #284346;
	display: flex;
	justify-content: center;
	align-items: center;
	background: linear-gradient(to right, #284346 50%, transparent 50%);
	background-size: 200% 100%;
	background-position: right bottom;
	transition: all .5s ease-out;
	position: relative;
	margin-top: 3.5rem;
}

span.EmailShare--button input {
	cursor: pointer;
	background: none;
	text-decoration: none;
	text-transform: uppercase;
	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: 1px;
	color: #284346;
	font-family: "Poppins", sans-serif;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

span.EmailShare--button svg {
	position: relative;
	width: 0;
	right: 0px;
	right: 0rem;
	opacity: 0;
	top: 0.5rem;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	transition: all 300ms ease-out;
	will-change: right, opacity;
}
span.EmailShare--button svg * {
	stroke-width: 5;
	stroke: transparent;
}
span.EmailShare--button:hover svg {
	opacity: 1;
	right: -1rem;
	width: 1em;
	top: 0.5rem;
}

span.EmailShare--button:hover {
	padding-right: 40px;
	background-position: left bottom;
	border: 1px solid #284346;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

span.EmailShare--button:hover input {
	color: white;
}

.EmailShareWindow--display {
	display: block;
}

// CTA--A
span.cta--a-btn {
	width: 40%;
	padding: 0.75em 1em 0.75em 1em;
	cursor: pointer;
	border: 1px solid white;
	display: flex;
	justify-content: center;
	align-items: center;
	background: linear-gradient(to right, white 50%, transparent 50%);
	background-size: 200% 100%;
	background-position: right bottom;
	transition: all .5s ease-out;
	position: relative;
	margin-top: 2rem;
	@media screen and (min-width: 1024px) {
		width: 40%;
	}
	@media screen and (min-width: 1278px) {
		width: 15%;
	}
}

span.cta--a-btn a {
	text-decoration: none;
	text-transform: uppercase;
	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: 1px;
	color: white;
	font-family: "Poppins", sans-serif;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

span.cta--a-btn svg {
	position: relative;
	width: 0;
	right: 0px;
	right: 0rem;
	opacity: 0;
	top: 0.5rem;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	transition: all 300ms ease-out;
	will-change: right, opacity;
}
span.cta--a-btn svg * {
	stroke-width: 5;
	stroke: transparent;
}
span.cta--a-btn:hover svg {
	opacity: 1;
	right: -1rem;
	width: 1em;
	top: 0.5rem;
}

span.cta--a-btn:hover {
	padding-right: 40px;
	background-position: left bottom;
	border: 1px solid white;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

span.cta--a-btn:hover a {
	color: #2a454a;
}

// Alert CSS
.alert {
	padding: 20px;
	background-color: #f44336; /* Red */
	color: white;
	margin-bottom: 15px;
	margin-top: 2rem;
  }
  
  /* The close button */
  .closebtn-alert {
	margin-left: 15px;
	color: white;
	font-weight: bold;
	float: right;
	font-size: 22px;
	line-height: 20px;
	cursor: pointer;
	transition: 0.3s;
  }
  
  /* When moving the mouse over the close button */
  .closebtn-alert:hover {
	color: black;
  }
  // Alert CSS
.success {
	padding: 20px;
	background-color: rgb(47, 255, 47); /* Red */
	color: white;
	margin-bottom: 15px;
	margin-top: 2rem;
  }
  
  /* The close button */
  .closebtn-success {
	margin-left: 15px;
	color: white;
	font-weight: bold;
	float: right;
	font-size: 22px;
	line-height: 20px;
	cursor: pointer;
	transition: 0.3s;
  }
  
  /* When moving the mouse over the close button */
  .closebtn-success:hover {
	color: black;
  }

  /* Random List */
.list_random {
	display: none;
	width: 100%;
}

