// prefooter reports

#main__prefooter {
	width: 100%;
	background: #e6eeec;
	background-size: cover;
	background-position: 50% 0;
	-webkit-transition: ease-in-out all 2.5s;
	-moz-transition: ease-in-out all 2.5s;
	-ms-transition: ease-in-out all 2.5s;
	-o-transition: ease-in-out all 2.5s;
	transition: ease-in-out all 2.5s;
	display: none;
}

.prefooter__container {
	width: 100%;
	height: 50vh;
	display: flex;
	justify-content: center;
	align-items: center;
	display: none;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

.prefooter__wrapper {
	width: 80%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #3ea89a;
	font-family: "Poppins", sans-serif;
	line-height: 2em;
	text-align: center;
	padding: 1em;
	display: none;
}

.prefooter__wrapper h3 {
	font-size: 1em;
	text-transform: uppercase;
	font-weight: 600;
	display: none;
}

.prefooter__wrapper h2 {
	font-size: 1.5rem;
	font-weight: 400;
	width: 100%;
	text-align: center;
	color: black;
	padding: 1rem;
	display: none;
}

// footer

.main__footer {
	width: 100%;
	min-height: 100vh;
	padding: 5em 0 3em 0;
	background: #3ea89a;
	background-position: 50% 0;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.main__footer-wrapper {
	width: 80%;
	display: grid;
	grid-row-gap: 5em;
	grid-column-gap: 1em;
	grid-template-columns: repeat(6, 1fr);
	grid-template-areas: "a b b b z z" "e f g h h h" "x x x x x x" "i j k l m y";
	align-items: start;
	box-sizing: border-box;
	z-index: 10;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

.footer__logo {
	grid-area: a;
	align-self: start;
}

.footer__logo a svg {
	width: 50%;
}

.footer__logo svg.year__five {
	width: 25%;
	margin-left: 1em;
}

.footer__info {
	grid-area: b;
	width: 100%;
}

.footer__info p {
	width: 80%;
	font-family: "Poppins", sans-serif;
	color: white;
	font-size: 1.25em;
	margin-bottom: 3em;
}

// footer cta
.footer__cta-wrap {
	width: 80%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.footer__cta-tag {
	width: 100%;
	margin-bottom: 1rem;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.footer__cta-tag h4 {
	color: white;
	text-transform: uppercase;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	letter-spacing: 0.05rem;
	font-size: 0.75rem;
}

.footer__cta-tag span {
	color: white;
}

.footer__cta-container {
	width: 100%;
}

.footer__cta-container h1 {
	font-family: "Poppins", sans-serif;
	font-size: 1.5rem;
	color: white;
	margin-bottom: 3rem;
	font-weight: 500;
	line-height: 2.25rem;
	@media screen and (max-width: 1440px) {
		font-size: 1rem;
	}
}
// footer cta

input[type=email] {
	width: 60%;
	font-weight: 300;
	border-radius: 1px;
	padding: 0.90rem;
	background: rgba(101, 186, 174, 1);
	border: none;
	outline: none;
	-webkit-appearance: none;
	border-radius: 0;
	font-size: 1rem;
	color: white;
	font-family: "Poppins", sans-serif;
}

input[type=email]::placeholder {
	color: rgba(255, 255, 255, 0.500);
	font: 1.1em "Poppins", Helvetica, Arial, Sans-serif;
	font-weight: 300;
	letter-spacing: 0.05em;
	font-size: 0.90rem;
	@media screen and (max-width: 1440px) {
		font-size: 0.65em;
	}
	@media screen and (max-width: 1024px) {
		font-size: 0.8rem;
	}
	@media screen and (max-width: 768px) {
		font-size: 0.65rem;
	}
}

.subscription__error {
	width: 100%;
	height: 4em;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

#mce-error-response {
	font-size: 14px;
	font-weight: 600;
	color: white;
	font-family: "Poppins", sans-serif;
}

#mce-error-response a {
	color: #2a454a;
	text-decoration: none;
	display: none;
}

#mce-error-response a:hover {
	color: white;
	transition: ease-in-out all 1s;
}

#mce-success-response {
	font-size: 14px;
	font-weight: 600;
	color: white;
	font-family: "Poppins", sans-serif;
}

.footer__email {
	grid-area: z;
	justify-self: right;
}

.footer__email a.email {
	font-size: 1em;
	font-family: "Poppins", sans-serif;
	color: white;
	position: relative;
	font-weight: 500;
	letter-spacing: 0.10em;
	text-decoration: none;
	cursor: pointer;
}

.footer__office {
	grid-area: f;
}

.footer__office h2 {
	font-size: 1.25em;
	font-family: "Poppins", sans-serif;
	color: white;
	font-weight: 600;
}

.contact__address p {
	font-size: 1em;
	font-family: "Poppins", sans-serif;
	color: rgba(255, 255, 255, 0.397);
	line-height: 2em;
}

.footer__office {
	display: block;
}

.footer__office h2 {
	font-size: 1.25em;
	font-family: "Poppins", sans-serif;
	color: white;
	font-weight: 600;
}

.contact__address p,
.contact__address a {
	font-size: 1em;
	font-weight: 400;
	font-family: "Poppins", sans-serif;
	color: rgba(255, 255, 255, 0.500);
	line-height: 2em;
	text-decoration: none;
}

.contact__address a.desktop__number {
	display: block;
}

.contact__address a.desktop__number:hover {
	color: white;
}

.contact__address p.mobile__dial a {
	display: none;
}

.footer__social {
	grid-area: g;
}

.footer__social h2 {
	font-size: 1.25em;
	font-family: "Poppins", sans-serif;
	color: white;
	font-weight: 600;
}

.social__links a {
	width: 100%;
	text-decoration: none;
	font-size: 1em;
	font-weight: 400;
	font-family: "Poppins", sans-serif;
	color: rgba(255, 255, 255, 0.500);
	line-height: 2em;
	position: relative;
}

.social__links a span.logo-space {
	margin-left: 1em;
}

.social__links a svg.facebook__footer-logo {
	width: 5%;
	height: 15px;
}

.social__links a svg.instagram__footer-logo {
	width: 5%;
}

.social__links a svg.linkedin__footer-logo {
	width: 5%;
}

.social__links a:hover {
	color: white;
}

.social__links a:hover svg path {
	color: white;
	fill: white !important;
}

.footer__copyright {
	grid-area: i;
	align-self: center;
}

.footer__copyright p {
	font-size: 0.65em;
	color: rgba(255, 255, 255, 0.329);
	font-family: "Poppins", sans-serif;
	font-weight: 600;
	text-transform: uppercase;
	text-decoration: none;
	@media screen and (max-width: 1440px) {
		font-size: 0.5em;
	}
}

.footer__copyright-mobile__only {
	grid-area: i;
	align-self: center;
	display: none;
}

.footer__copyright-mobile__only p {
	font-size: 0.65em;
	color: rgba(255, 255, 255, 0.329);
	font-family: "Poppins", sans-serif;
	font-weight: 600;
	text-transform: uppercase;
}

.mobile__terms__privacy-container {
	display: none;
}

.footer__terms-mobile {
	grid-area: j;
	align-self: center;
	display: none;
}

.footer__terms-mobile a {
	text-decoration: none;
	font-size: 0.65em;
	color: white;
	font-family: "Poppins", sans-serif;
	text-transform: uppercase;
	font-weight: 600;
	display: none;
}

.footer__privacy-mobile {
	grid-area: k;
	align-self: center;
	display: none;
}

.footer__privacy-mobile a {
	text-decoration: none;
	font-size: 0.65em;
	color: white;
	font-family: "Poppins", sans-serif;
	text-transform: uppercase;
	font-weight: 600;
	display: none;
}

.footer__terms {
	grid-area: j;
	align-self: center;
}

.footer__terms a {
	text-decoration: none;
	font-size: 0.65em;
	color: white;
	font-family: "Poppins", sans-serif;
	text-transform: uppercase;
	font-weight: 600;
	line-height: 2em;
	background-position: 0% 100%;
	background-repeat: no-repeat;
	@media screen and (max-width: 1440px) {
		font-size: 0.5em;
	}
}

.footer__terms a:hover,
.footer__terms a:focus {
	color: rgba(255, 255, 255, 0.397);
	transition: ease-in-out 0.2s;
}

.footer__privacy {
	grid-area: k;
	align-self: center;
}

.footer__privacy a {
	text-decoration: none;
	font-size: 0.65em;
	color: white;
	font-family: "Poppins", sans-serif;
	text-transform: uppercase;
	font-weight: 600;
	line-height: 2em;
	background-position: 0% 100%;
	background-repeat: no-repeat;
}

.footer__privacy a:hover,
.footer__privacy a:focus {
	color: rgba(255, 255, 255, 0.397);
	transition: ease-in-out 0.2s;
}

.footer__tag {
	grid-area: y;
	text-align: right;
}

.footer__tag p {
	font-size: 0.65em;
	font-weight: 600;
	color: white;
	font-family: "Poppins", sans-serif;
	@media screen and (max-width: 1440px) {
		font-size: 0.5em;
	}
}

.mobile__footer {
	display: none;
}

@media only screen and (max-width: 1024px) {
	.main__footer {
		display: none;
	}

	.mobile__footer {
		display: block;
		@media only screen and (max-width: 840px) and (orientation: landscape) {
			padding-bottom: 6rem;
		}
	}

	.mobile__footer {
		display: block;
		width: 100%;
		min-height: 100vh;
		background: #3ea89a;
		padding-top: 5rem;
	}

	.mobile__wrapper {
		width: 80%;
		height: 100vh;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: flex-start;
		transition: ease-in-out all 0.5s;
		-webkit-transition: ease-in-out all 0.5s;
		-moz-transition: ease-in-out all 0.5s;
		-o-transition: ease-in-out all 0.5s;
	}

	.mobile__logo {
		width: 100%;
		@media only screen and (max-width: 840px) and (orientation: landscape) {
			padding-top: 2rem;
		}
	}

	.mobile__footer a svg {
		width: 115px;
	}
	.mobile__footer svg.mobile__five {
		width: 65px;
		margin-left: 1em;
	}

	.mobile__info {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	}

	.mobile__info p {
		width: 80%;
		font-family: "Poppins", sans-serif;
		color: white;
		font-size: 1em;
		padding-bottom: 1em;
		@media only screen and (max-width: 840px) and (orientation: landscape) {
			padding-top: 2rem;
		}
	}

	.mobile__info-inputs {
		width: 80%;
		display: flex;
		align-items: center;
	}

	.mobile__info .mobile__info-inputs input[type=email].mobile__input {
		width: 100%;
		font-weight: 300;
		border-radius: 1px;
		padding: 0.5em;
		background: #65baae;
		border: none;
		outline: none;
		font-size: 1rem;
		@media screen and (max-width: 768px) {
			font-size: 1rem;
		}
	}

	.mobile__info input[type=text] {
		width: 100%;
		max-width: 150px;
		font: 0.75em "Poppins", Helvetica, Arial, Sans-serif;
		font-weight: 600;
		border-radius: 1px;
		padding: 0.2em;
		background: rgba(101, 186, 174, 1);
		border: none;
		color: white;
		outline: none;
	}

	.mobile__info input[type=text]::placeholder {
		color: white;
	}

	.mobile__info input[type=submit].mobile__submit {
		font: 14px "Poppins", Helvetica, Arial, Sans-serif;
		max-width: 150px;
		font-weight: 600;
		border-radius: 1px;
		padding: 0.500em;
		margin-left: 1rem;
		width: 40%;
		color: #3ea89a;
		background: white;
		-webkit-appearance: none;
		border-radius: 0;
		cursor: pointer;
	}

	.mobile__contact {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	.mobile__address-social {
		max-width: 280px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		@media only screen and (max-width: 840px) and (orientation: landscape) {
			padding-top: 2rem;
		}
	}

	.mobile__office {
		margin-right: 2em;
		line-height: 1.5em;
	}

	.mobile__office h2 {
		color: white;
		font-size: 1em;
		font-weight: 600;
		font-family: "Poppins", sans-serif;
	}

	.mobile__office .mobile__address p {
		color: rgba(255, 255, 255, 0.397);
		font-family: "Poppins", sans-serif;
		font-weight: 500;
		font-size: 0.75em;
	}

	.mobile__office .mobile__address a {
		color: rgb(255, 255, 255);
		font-family: "Poppins", sans-serif;
		font-weight: 500;
		font-size: 1em;
		text-decoration: none;
		padding: 1rem 1rem 1rem 0;
	}

	.mobile__email a.email {
		color: rgb(255, 255, 255);
		font-family: "Poppins", sans-serif;
		font-weight: 600;
		font-size: 0.75em;
		text-decoration: none;
	}

	.mobile__social {
		line-height: 1.5em;
	}

	.mobile__social h2 {
		color: white;
		font-size: 1em;
		font-weight: 600;
		font-family: "Poppins", sans-serif;
	}

	.mobile__social .mobile__links a {
		color: rgba(255, 255, 255, 0.397);
		font-size: 0.75em;
		font-weight: 600;
		font-family: "Poppins", sans-serif;
		text-decoration: none;
	}

	.mobile__privacy {
		width: 100%;
	}

	.mobile__conditions {
		width: 100%;
		display: flex;
		margin-bottom: 0.75em;
	}

	.mobile__conditions a.mobile__terms__margin {
		margin-right: 1em;
	}

	.mobile__conditions a {
		color: white;
		font-size: 0.75em;
		font-weight: 600;
		font-family: "Poppins", sans-serif;
		text-decoration: none;
	}

	.mobile__copyright {
		width: 100%;
	}

	.mobile__copyright p {
		color: rgba(255, 255, 255, 0.397);
		font-size: 0.75em;
		font-weight: 300;
		font-family: "Poppins", sans-serif;
	}
}

//underline animation on hover follow throughj on mouse out
