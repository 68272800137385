//top header
.privacy__hero {
	width: 100%;
	height: 80vh;
	background: #00584f;
	display: flex;
	justify-content: center;
	align-items: center;
}

.privacy__title-wrap {
	width: 80%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	position: relative;
	top: 10rem;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

.privacy__title-tag {
	width: 100%;
	margin-bottom: 3rem;
	display: flex;
	align-items: center;
}

.privacy__title-tag h4 {
	color: white;
	text-transform: uppercase;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	letter-spacing: 0.05rem;
	font-size: 1.35rem;
	padding-left: 2rem;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 0.8rem;
	}
	@media only screen and (min-width: 620px) {
		font-size: 1.5vw;
	}
	@media only screen and (min-width: 768px) {
		font-size: 1.25vw;
	}
	@media only screen and (min-width: 920px) {
		font-size: 1vw;
	}
	@media only screen and (min-width: 1278px) {
		font-size: 0.7vw;
	}
	@media only screen and (min-width: 1920px) {
		font-size: 0.6vw;
	}
	@media only screen and (max-width: 840px) and (orientation: landscape) {
		font-size: 1.5vw;
	}
}

.privacy__title-tag span {
	height: 0.1rem;
	background: white;
	width: 3rem;
}

.privacy__title-container {
	width: 100%;
}

.privacy__title-container h1 {
	font-family: "Poppins", sans-serif;
	font-size: 3rem;
	color: white;
	font-weight: 600;
	letter-spacing: 0.15rem;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 2rem;
	}
	@media (min-width: 599px) and (max-width: 1024px) {
		font-size: 2.5rem;
	}
	@media (min-width: 1024px) and (max-width: 1920px) {
		font-size: 3rem;
		line-height: 3.75rem;
	}
	@media only screen and (max-width: 840px) and (orientation: landscape) {
		font-size: 1rem;
	}
}

.privacy__content {
	width: 100%;
	height: 100%;
	padding: 5rem 0 5rem 0;
	background: white;
	display: flex;
	justify-content: center;
}

.privacy__content-wrapper {
	width: 80%;
	height: 100%;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

.privacy__pretext {
	width: 100%;
	margin-bottom: 2rem;
}

.privacy__pretext p {
	color: black;
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
	font-size: 1.35rem;
	line-height: 2rem;
}

.privacy__blocks {
	width: 100%;
	margin-bottom: 2rem;
}

.privacy__block-title h1 {
	color: black;
	font-family: "Poppins", sans-serif;
	font-size: 2rem;
	margin-bottom: 1rem;
	@media only screen and (max-width: 651px) {
		font-size: 1.5rem;
	}
}

.privacy__block-text p {
	color: black;
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
}

.privacy__block-text h3 {
	color: black;
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
	font-style: italic;
}
