.post {
    width: 100%;
    height: 100%;
    background: #E6F2EE;
    transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

.post__wrapper {
    width: 100%;
    height: 100%;
}

.post__hero {
    width: 100%;
    height: 35vh;
    background: lightgrey;
    @media screen and (min-width: 1024px) {
        height: 65vh;
    }
}

.post__hero img {
    width: 100%;
    height: 35vh;
    object-fit: cover;
    object-position: center;
    @media screen and (min-width: 1024px) {
        height: 65vh;
    }
}

.post__content-wrapper {
    width: 90%;
    height: 100%;
    background: #E6F2EE;
    padding-bottom: 6em;
    margin-top: 4rem;
    padding-left: 0;
    margin: 0 auto;
    @media screen and (min-width: 768px) {
        width: 80%;
        margin: 0 auto;
        padding-bottom: 4rem;
        margin-top: 2rem;
        padding-left: 0;
    }
    @media screen and (min-width: 1024px) {
        width: 80%;
        margin: 0 auto;
        padding-bottom: 6rem;
        padding-left: 0;
    }
    @media screen and (min-width: 1278px) {
        width: 80%;
        margin: 0 auto;
        padding-bottom: 6rem;
        padding-left: 0;
    }
}

.post__title-container {
    @media screen and (min-width: 1024px) {
        display: flex;
        padding-top: 7rem;
    }
}

.post__title-content {
    width: 100%;
}

.field-tag {
	width: 100%;
	margin-bottom: 2rem;
	display: flex;
    align-items: center;
    margin-top: 4rem;
    @media screen and (min-width: 768px) {
        margin-top: 0;
    }
}

.field-tag h4 {
    padding-left: 2rem;
}

.field-tag span {
	height: 0.1rem;
	background: black;
	width: 3rem;
}

.post h1 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: #279989;
    font-size: 3rem;
    line-height: 3.25rem;
    @media screen and (min-width: 1024px) {
        font-size: 5rem;
        line-height: 5.5rem;
        width: 80%;
    }
}

.post__subtitle {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.post h2 {
    font-family: "Neue Haas Grotesk Text Pro", sans-serif;
    color: #939598;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 300;
    @media screen and (min-width: 1024px) {
        font-size: 2.25rem;
        line-height: 3.5rem;
        width: 80%;
    }
}

.post h4 {
    color: #939598;
	text-transform: uppercase;
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	letter-spacing: 0.05rem;
    font-size: 0.75rem;
    padding-bottom: 0.2rem;
}

.post h3 {
    color: black;
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	letter-spacing: 0.05rem;
	font-size: 1rem;
}

.post h5 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 0.8rem;
    color: black;
    padding-right: 2rem;
}

.post h6 {
    color: black;
	font-family: 'Poppins', sans-serif;
	font-weight: 300;
	letter-spacing: 0.05rem;
	font-size: 0.75rem;
}

h6.img-desc {
    color: black;
    text-transform: none;
    padding-top: 0.5rem;
    font-style: italic;
}

.post h6.img-title {
    color: #939598;
    text-transform: uppercase;
    padding-top: 1rem;
}

.post__contributors {
    padding-bottom: 2rem;
    padding-top: 2rem;
    @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
        padding-bottom: 2rem;
    }
}

.post__contributors h4.contributors {
    padding-top: 0.5rem;
    color: black;
    @media screen and (min-width: 768px) {
        padding-top: 0;
        padding-left: 1rem;
    }
}

.post__title-project {
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    @media screen and (min-width: 1024px) {
        padding-top: 4.5rem;
    }
}

.post__project-client h4 {
    color: black;
}

.post__divider {
    height: 2px;
    background: black;
    width: 90%;
    margin-bottom: 0.5rem;
    @media screen and (min-width: 768px) {
        width: 90%;
    }
    @media screen and (min-width: 1024px) {
        width: 60%;
    }
}

.post__project-info {
    padding-bottom: 5rem;
    @media screen and (min-width: 768px) {
        display: flex;
    }
    @media screen and (min-width: 1024px) {
        display: block;
        width: 50%;
        margin-left: auto;
    }
}

.desktop__border {
    display: none;
    @media screen and (min-width: 1024px) {
        display: block;
        height: 2px;
        background: #939598;
        width: 100%;
        margin: 0 auto;
        margin-top: 3rem;
        margin-bottom: 5rem;
    }
}


.post__quote {
    padding-bottom: 5rem;
}

.post blockquote {
    width: 100%;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: black;
    font-style: italic;
    font-size: 1.55rem;
    line-height: 2rem;
    padding-left: 2rem;
    @media screen and (min-width: 1024px) {
        font-size: 2.25rem;
        line-height: 3rem;
    }
}

.post blockquote span.intro__block {
    padding-left: 0;
}

.post p {
    font-family: "Neue Haas Grotesk Text Pro", sans-serif;
    font-size: 1.25rem;
    line-height: 1.75rem;
    @media screen and (min-width: 1024px) {
        width: 70%;
        margin: 0 auto;
        font-size: 1.5rem;
        line-height: 2.5rem;
    }
}

// temp img placeholder
.post__img {
    width: 100%;
    height: 35vh;
    background: lightgrey;
    @media screen and (min-width: 1024px) {
        margin-top: 4rem;
        width: 80%;
        margin: 0 auto;
        height: 65vh;
    }
}

.post__img img {
    width: 100%;
    height: 35vh;
    object-fit: cover;
    object-position: center;
    @media screen and (min-width: 1024px) {
        height: 65vh;
        margin-top: 4rem;
        margin: 0 auto;
    }
}



.post__img-full {
    width: 100%;
    height: 35vh;
    background: lightgrey;
    @media screen and (min-width: 1024px) {
        width: 100%;
        height: 75vh;
        margin-bottom: 6rem;
    }
}

.post__img-full img {
    width: 100%;
    height: 35vh;
    object-fit: cover;
    object-position: center;
    @media screen and (min-width: 1024px) {
        height: 75vh;
        margin-bottom: 6rem;
    }
}

.post__img-credit {
    padding-bottom: 2rem;
    @media screen and (min-width: 1024px) {
        padding-left: 0;
        padding-bottom: 6rem;
        width: 70%;
        margin: 0 auto;
    }
}

.post__border-quote {
    padding-left: 2rem;
    padding-top: 2rem;
    @media screen and (min-width: 768px) {
        padding-top: 5rem;
        width: 75%;
        margin: 0 auto;
    }
    @media screen and (min-width: 1024px) {
        width: 85%;
    }
}

.post__section-quote {
    border-left: 2px solid #2A4549;
}

.post__section-quote-author {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
}

.post__section-quote-author span.title {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 0.7rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    color: #939598;
    padding-left: 2rem;
    @media screen and (min-width: 1024px) {
        width: 100%;
    }
}

.post__section-social {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 4rem;
    padding-left: 2rem;
    padding-top: 2rem;
    @media screen and (min-width: 1024px) {
        display: none;
    }
}

.post__section-social-icons svg {
    width: 10%;
    margin-right: 1rem;
    @media screen and (min-width: 768px){
        width: 5%;
    }
    @media screen and (min-width: 1024px){
        width: 3.5%;
    }
}

.post__section-social-icons svg path.a {
    fill: #ffffff;
}

.seperator {
    height: 1px;
    background: black;
    width: 90%;
    margin-bottom: 0.5rem;
    margin: 0 auto;
    @media screen and (min-width: 768px) {
        width: 80%;
    }
    @media screen and (min-width: 1024px) {
        margin: 0 auto;
        margin-top: 2rem;
        margin-bottom: 4rem;
    }
}


.post__portrait-container {
    display: flex;
    @media screen and (min-width: 768px){
        width: 100%;
    }
}

.post__portrait {
    border-radius: 50%;
    margin-top: 0;
    margin-bottom: 20px;
    height: 80px;
    width: 80px;
    overflow: hidden;
    background: lightgrey;
    @media screen and (min-width: 768px) {
        height: 115px;
        width: 115px;
    }
}

.post__portrait img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: block;
}

.post__portrait-description {
    padding-left: 1rem;
    width: 70%;
    @media screen and (min-width: 768px) {
        padding-left: 2rem;
        width: 50%;
    }
}



.post h3.contributor {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 1.25rem;
    color: black;
    @media screen and (min-width: 1024px) {
        font-size: 1.5rem;
    }
}

.post h4.title {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 0.75rem;
    color: #279989;
    margin-bottom: 0.5rem;
    @media screen and (min-width: 1024px) {
        font-size: 1rem;
    }
}


.post span.bio {
    font-family: "neue-haas-grotesk-text-pro", sans-serif;
    font-weight: 300;
    font-size: 0.7rem;
    color: black;
    line-height: 1.25rem;
    @media screen and (min-width: 1024px) {
        margin: 0;
        font-size: 1rem;
        line-height: 1.50rem;
    }
}




