.openBtn {
	cursor: pointer;
	@media screen and (max-width: 608px) {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.closeBtn {
	cursor: pointer;
	position: relative;
	bottom: 10px;
	width: 32px;
	height: 32px;
	opacity: 0.3;
}

.closeBtn:hover {
	opacity: 1;
}

.closeBtn:before,
.closeBtn:after {
	position: absolute;
	left: 15px;
	content: ' ';
	height: 33px;
	width: 2px;
	background-color: #333;
}
.closeBtn:before {
	transform: rotate(45deg);
}
.closeBtn:after {
	transform: rotate(-45deg);
}

//Individual Profile Sliders
#profile__slider-jb {
	z-index: 100000;
	position: fixed;
	overflow: hidden;
	width: 100%;
	height: 100vh;
	right: -100%;
	bottom: 1px;
	overflow-y: scroll;
}

//test profile on fixed

#profile__slider-rg {
	z-index: 100000;
	position: fixed;
	overflow: hidden;
	width: 100%;
	height: 100vh;
	right: -100%;
	bottom: 1px;
	overflow-y: scroll;
}

#profile__slider-tz {
	z-index: 100000;
	position: fixed;
	overflow: hidden;
	width: 100%;
	height: 100vh;
	right: -100%;
	bottom: 1px;
	overflow-y: scroll;
}

#profile__slider-tb {
	z-index: 100000;
	position: fixed;
	overflow: hidden;
	width: 100%;
	height: 100vh;
	right: -100%;
	bottom: 1px;
	overflow-y: scroll;
}

#profile__slider-js {
	z-index: 100000;
	position: fixed;
	overflow: hidden;
	width: 100%;
	height: 100vh;
	right: -100%;
	bottom: 1px;
	overflow-y: scroll;
}

#profile__slider-kh {
	z-index: 100000;
	position: fixed;
	overflow: hidden;
	width: 100%;
	height: 100vh;
	right: -100%;
	bottom: 1px;
	overflow-y: scroll;
}

#profile__slider-gb {
	z-index: 100000;
	position: fixed;
	overflow: hidden;
	width: 100%;
	height: 100vh;
	right: -100%;
	bottom: 1px;
	overflow-y: scroll;
}

.top__slider-title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 80%;
	@media screen and (max-width: 768px) {
		padding-top: 2rem;
	}
}

.slider__change {
	line-height: 0.5em;
}

// End Sliders

.slider__container {
	width: 100%;
}

.slider__wrapper {
	width: 100%;
	display: flex;
}

.slider__exit {
	width: 30%;
	min-height: 100vh;
	background: rgba(233, 233, 233, 0.6);
	@media screen and (max-width: 1248px) {
		display: none;
	}
}

.slider__profile {
	width: 75%;
	background: white;
	min-height: 100vh;
	@media screen and (max-width: 1248px) {
		width: 100%;
	}
	@media screen and (max-width: 768px) {
		width: 100%;
	}
}

.slider__top {
	width: 100%;
	height: 100%;
	display: flex;
	@media screen and (max-width: 768px) {
		display: flex;
		flex-direction: column-reverse;
		justify-content: flex-start;
		align-items: center;
	}
}

.slider__image {
	width: 50%;
	@media screen and (max-width: 768px) {
		width: 100vw;
		background: white;
		margin: 0 auto;
		display: flex;
		justify-content: center;
	}
}

.slider__image img {
	width: 640px;
	height: 1080px;
	position: fixed;
	@media screen and (max-width: 1630px) {
		width: 520px;
		height: 100%;
	}
	@media screen and (max-width: 1440px) {
		width: 480px;
		height: 100%;
	}
	@media screen and (max-width: 1248px) {
		width: 550px;
		height: 100%;
	}
	@media screen and (max-width: 1161px) {
		width: 520px;
		height: 100%;
	}
	@media screen and (max-width: 1024px) {
		width: 500px;
		height: 100%;
	}
	@media screen and (max-width: 920px) {
		width: 420px;
		height: 100%;
	}
	@media screen and (max-width: 768px) {
		height: 100%;
		width: 100%;
		position: relative;
	}
}

.slider__info {
	width: 55%;
	padding: 2em;
	padding-left: 4.5rem;
	padding-bottom: 4.5em;
	@media screen and (max-width: 768px) {
		width: 80%;
		min-height: 100vh;
		padding: 0;
		margin: 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
}

.slider__info h2.section__num {
	font-size: 1.5em;
	font-family: "Poppins", sans-serif;
	margin-bottom: 1em;
}

.slider__seperator {
	width: 80%;
	margin-top: 1em;
	margin-bottom: 2em;
	border-bottom: 1px solid black;
}

.slider__info h1 {
	font-size: 6rem;
	font-weight: 600;
	font-family: "Poppins", sans-serif;
	line-height: 1em;
	margin-bottom: 0.25rem;
	@media screen and (max-width: 1440px) {
		font-size: 4rem;
	}
	@media screen and (max-width: 1024px) {
		font-size: 3rem;
	}
	@media screen and (max-width: 768px) {
		font-size: 4.5rem;
		text-align: center;
	}
}

.slider__info h2.section__subhead {
	font-size: 2em;
	font-family: "Poppins", sans-serif;
	color: #3ea89a;
	margin-bottom: 0.75rem;
	font-weight: 600;
	@media screen and (max-width: 1440px) {
		font-size: 1.5rem;
	}
	@media screen and (max-width: 1024px) {
		font-size: 1rem;
	}
}

.slider__info p {
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
	font-size: 1.25rem;
	line-height: 2rem;
	margin-bottom: 1em;
	padding-right: 5rem;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 1.25rem;
		padding: 1rem;
		line-height: 2rem;
		text-align: left;
		margin-right: 0;
	}
	@media (min-width: 599px) and (max-width: 1024px) {
		font-size: 1.25rem;
		line-height: 2rem;
		text-align: left;
		margin-right: 0;
		padding-right: 0;
	}
	@media (min-width: 1024px) and (max-width: 1920) {
		font-size: 1.25rem;
		line-height: 2rem;
		text-align: left;
		margin-right: 0;
		padding-right: 0;
		padding-left: 1rem;
	}
}

.slider__info p span.moretext {
	display: none;
}

.slider__info span.moreless-button {
	text-decoration: none;
	font-size: 1em;
	font-family: "Poppins", sans-serif;
	color: black;
	transition: 0.2s;
	cursor: pointer;
}

.slider__bottom {
	width: 100%;
}

.slider__bottom-title h1 {
	font-family: "Poppins", sans-serif;
	font-size: 2em;
	font-weight: 300;
}

.slider__bottom-projects {
	width: 100%;
	margin-top: 1em;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 1fr);
	row-gap: 2em;
}

.slider__project h2 {
	font-family: "Poppins", sans-serif;
	font-size: 1em;
	font-weight: 300;
	border-top: 1px solid black;
	width: 40%;
}

span#colour__alt {
	color: black;
}
