.error__wrapper {
	width: 100%;
	height: 100vh;
	background-image: url("https://elasticbeanstalk-us-west-2-412710462953.s3-us-west-2.amazonaws.com/images/404-bg.jpg");
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
	@media screen and (min-width: 2560px) {
		background-image: url("https://elasticbeanstalk-us-west-2-412710462953.s3-us-west-2.amazonaws.com/images/404-bg.jpg");
	}
	@media screen and (min-width: 3840px) {
		background-image: url("https://elasticbeanstalk-us-west-2-412710462953.s3-us-west-2.amazonaws.com/images/404-bg.jpg");
	}
}

.error__container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	line-height: 5em;
	color: white;
	font-family: "Poppins", sans-serif;
	font-weight: 300;
	@media screen and (max-width: 634px) {
		text-align: center;
	}
}
