.contact__full-wrapper {
    width: 100%;
}

.contact__header {
	width: 100%;
    min-height: 75vh;
	background: #279989;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

.contact__hero {
	width: 100%;
	min-height: 75vh;
	padding-top: 5rem;
	background: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 5rem;
}

.contact__title-wrap {
	width: 80%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	@media only screen and (max-width: 840px) and (orientation: landscape) {
		position: relative;
		top: 50px;
		left: 50px;
	}
}

.contact__title-tag {
	width: 100%;
	margin-bottom: 3rem;
	display: flex;
	align-items: center;
}

.contact__title-tag h4 {
	color: white;
	text-transform: uppercase;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	letter-spacing: 0.05rem;
	font-size: 1.35rem;
	padding-left: 2rem;
	@media (min-width: 320px) and (max-width: 619px) {
		font-size: 0.8rem;
	}
	@media only screen and (min-width: 620px) {
		font-size: 1.5vw;
	}
	@media only screen and (min-width: 768px) {
		font-size: 1.25vw;
	}
	@media only screen and (min-width: 920px) {
		font-size: 1vw;
	}
	@media only screen and (min-width: 1278px) {
		font-size: 0.7vw;
	}
	@media only screen and (min-width: 1920px) {
		font-size: 0.6vw;
	}
	@media only screen and (max-width: 840px) and (orientation: landscape) {
		font-size: 1.5vw;
	}
}

.contact__title-tag span {
	height: 0.1rem;
	background: white;
	width: 3rem;
}

.contact__title-container {
	width: 100%;
	height: 100%;
}

.contact__title-container h1 {
	font-family: "Poppins", sans-serif;
	font-size: 3rem;
	color: white;
	font-weight: 600;
	letter-spacing: 0.15rem;
	margin-bottom: 1rem;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 2rem;
	}
	@media (min-width: 599px) and (max-width: 1024px) {
		font-size: 2.5rem;
	}
	@media (min-width: 1024px) and (max-width: 1920px) {
		font-size: 3rem;
		line-height: 3.75rem;
	}
	@media only screen and (max-width: 840px) and (orientation: landscape) {
		font-size: 1rem;
	}
}

.contact__title-container p {
    width: 43%;
    font-weight: 300;
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
	color: white;
    line-height: 1.75rem;
    font-size: 1.5rem;
    padding-top: 2rem;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 1.15rem;
		width: 100%;
	}
	@media (min-width: 599px) and (max-width: 1024px) {
		font-size: 1.15rem;
		width: 80%;
	}
	@media (min-width: 1024px) and (max-width: 1920) {
		font-size: 1.5rem;
        width: 55%;
        padding-top: 2rem;
	}
}

.contact__content-container {
    width: 100%;
    height: 100%;
    background: white;
    display: flex;
    justify-content: center;
}

.contact__content-wrapper {
    width: 80%;
    height: 100%;
    padding-top: 5rem;
    padding-bottom: 5rem;
    z-index: 18;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

// FORM STYLES

p.form-message {
    list-style: none;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 2rem;
}

.form-error {
    color: red;
}

.form-success {
    color: green;
}

.input-error {
    box-shadow: 0 0 5px red;
}

.contact__content-wrapper form {
    width: 70%;
    height: 100%;
    padding: 5rem 2rem 5rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: 1024px) {
        width: 100%;
        padding-right: 0;
    }
}

.contact__name {
    display: flex;
    width: 100%;
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}

// FIRST NAME
.contact__form-fname {
    width: 100%;
    margin-right: 2rem;
    display: flex;
    flex-direction: column-reverse;
}

.contact__form-fname label {
    font-family: "Poppins", sans-serif;
    color: black;
    font-size: 1em;
    padding-bottom: 1em;
    padding-top: 1em;
}

.contact__form-fname input[type=text] {
    width: 100%;
    font-weight: 300;
    border-radius: 1px;
    padding: 0.5em;
    background: #E6F2EE;
    border: none;
    border-bottom: 2px solid black;
    outline: none;
    font-size: 1rem;
}

// LAST NAME
.contact__form-lname {
    width: 100%;
    margin-right: 2rem;
    display: flex;
    flex-direction: column-reverse;
}

.contact__form-lname label {
    font-family: "Poppins", sans-serif;
    color: black;
    font-size: 1em;
    padding-bottom: 1em;
    padding-top: 1em;
}

.contact__form-lname input[type=text] {
    width: 100%;
    font-weight: 300;
    border-radius: 1px;
    padding: 0.5em;
    background: #E6F2EE;
    border: none;
    border-bottom: 2px solid black;
    outline: none;
    font-size: 1rem;
}

.contact__type {
    display: flex;
    width: 100%;
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}

// EMAIL
.contact__form-email {
    width: 100%;
    margin-right: 2rem;
    display: flex;
    flex-direction: column-reverse;
}

.contact__form-email label {
    font-family: "Poppins", sans-serif;
    color: black;
    font-size: 1em;
    padding-bottom: 1em;
    padding-top: 1em;
}

.contact__form-email input[type=text] {
    width: 100%;
    font-weight: 300;
    border-radius: 1px;
    padding: 0.5em;
    background: #E6F2EE;
    border: none;
    border-bottom: 2px solid black;
    outline: none;
    font-size: 1rem;
}

// PHONE NUMBER
.contact__form-phone {
    width: 100%;
    margin-right: 2rem;
    display: flex;
    flex-direction: column-reverse;
}

.contact__form-phone label {
    font-family: "Poppins", sans-serif;
    color: black;
    font-size: 1em;
    padding-bottom: 1em;
    padding-top: 1em;
}

.contact__form-phone input[type=text] {
    width: 100%;
    font-weight: 300;
    border-radius: 1px;
    padding: 0.5em;
    background: #E6F2EE;
    border: none;
    border-bottom: 2px solid black;
    outline: none;
    font-size: 1rem;
}

// COMPANY
.contact__company {
    width: 47%;
    @media screen and (max-width: 768px) {
        width: 100%;
    }
}
.contact__form-company {
    display: flex;
    flex-direction: column-reverse;
}

.contact__form-company label {
    font-family: "Poppins", sans-serif;
    color: black;
    font-size: 1em;
    padding-bottom: 1em;
    padding-top: 1em;
}

.contact__form-company input[type=text] {
    width: 100%;
    font-weight: 300;
    border-radius: 1px;
    padding: 0.5em;
    background: #E6F2EE;
    border: none;
    border-bottom: 2px solid black;
    outline: none;
    font-size: 1rem;
}

// SUBJECT
.contact__form-subject {
    display: flex;
    flex-direction: column;
}

.contact__form-subject label {
    font-family: "Poppins", sans-serif;
    color: black;
    font-size: 1em;
    padding-bottom: 1em;
    padding-top: 1em;
}

.contact__form-subject textarea[id=message] {
    width: 97%;
    font-weight: 300;
    border-radius: 1px;
    padding: 0.5em;
    background: #E6F2EE;
    border: none;
    border-bottom: 2px solid black;
    outline: none;
    font-size: 1rem;
}

// checkbox
.contact__form-cbox {
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    align-items: center;
}

.contact__form-cbox input[type="checkbox"] {
    -webkit-appearance: none;
    width: 1.2rem;
    height: 1.2rem;
    border: 2px solid #808080;
}

.contact__form-cbox input[type="checkbox"]:focus {
    border: 2px solid #808080;
}

.contact__form-cbox input[type="checkbox"]:checked {
    position: relative;
    background: none;
}

.contact__form-cbox input[type="checkbox"]:checked:after {
    position: absolute;
    left: 0.2rem;
    bottom: 0.01rem;
    content: "✓";
}

.contact__form-cbox label {
    font-family: "Poppins", sans-serif;
    color: black;
    font-size: 1em;
    padding-bottom: 1em;
    padding-top: 1em;
    padding-left: 1rem;
}

// form btn

span.contact__form-sub {
	padding: 0.9rem;
	cursor: pointer;
	border: 1px solid #284346;
	background: linear-gradient(to right, #284246 50%, transparent 50%);
	background-size: 200% 100%;
	background-position: right bottom;
	transition: all .5s ease-out;
}

span.contact__form-sub input[type=submit] {
	text-decoration: none;
	text-transform: uppercase;
	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: 1px;
	color: #284346;
	background-color: transparent;
	font-family: "Poppins", sans-serif;
	transition: all .5s ease-out;
	cursor: pointer;
}

span.contact__form-sub svg {
	position: relative;
	width: 0;
	right: 0px;
	right: 0rem;
	opacity: 0;
	top: 0.40rem;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	transition: all 300ms ease-out;
	will-change: right, opacity;
}

span.contact__form-sub svg * {
	stroke-width: 5;
	stroke: transparent;
}

//FIX ARROW JUMP

span.contact__form-sub:hover svg {
	opacity: 1;
	right: -0.75rem;
	width: 0.75em;
	top: 0.45rem;
}

span.contact__form-sub:hover {
	padding-right: 30px;
	background-position: left bottom;
	border: 1px solid #284246;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

span.contact__form-sub:hover input[type=submit] {
	color: white;
}

span#status p.status_success {
    width: 97%;
    margin: 10px 0;
    padding: 10px;
    border-radius: 3px 3px 3px 3px;
    color: white;
    background-color: #279989;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400px;
}

span#status p.status_error {
    width: 97%;
    margin: 10px 0;
    padding: 10px;
    border-radius: 3px 3px 3px 3px;
    color: #D8000C;
    background-color: #FFBABA;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400px;
}