//top header
.about__hero {
	width: 100%;
	height: 80vh;
	background: #00584f;
	display: flex;
	justify-content: center;
	align-items: center;
	@media only screen and (max-width: 840px) and (orientation: landscape) {
		height: 100vh;
	}
}

.about__title-wrap {
	width: 80%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	position: relative;
	top: 10rem;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
	@media only screen and (max-width: 840px) and (orientation: landscape) {
		position: relative;
		top: 50px;
		left: 70px;
	}
}

.about__title-tag {
	width: 100%;
	margin-bottom: 3rem;
	display: flex;
	align-items: center;
}

.about__title-tag h4 {
	color: white;
	text-transform: uppercase;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	letter-spacing: 0.05rem;
	font-size: 1.35rem;
	padding-left: 2rem;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 0.8rem;
	}
	@media only screen and (min-width: 620px) {
		font-size: 1.5vw;
	}
	@media only screen and (min-width: 768px) {
		font-size: 1.25vw;
	}
	@media only screen and (min-width: 920px) {
		font-size: 1vw;
	}
	@media only screen and (min-width: 1278px) {
		font-size: 0.7vw;
	}
	@media only screen and (min-width: 1920px) {
		font-size: 0.6vw;
	}
	@media only screen and (max-width: 840px) and (orientation: landscape) {
		font-size: 1.5vw;
	}
}

.about__title-tag span {
	height: 0.1rem;
	background: white;
	width: 3rem;
}

.about__title-container {
	width: 100%;
}

.about__title-container h1 {
	font-family: "Poppins", sans-serif;
	font-size: 3rem;
	color: white;
	font-weight: 600;
	letter-spacing: 0.15rem;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 2rem;
	}
	@media (min-width: 599px) and (max-width: 1024px) {
		font-size: 2.5rem;
	}
	@media (min-width: 1024px) and (max-width: 1920px) {
		font-size: 3rem;
		line-height: 3.75rem;
	}
	@media only screen and (max-width: 840px) and (orientation: landscape) {
		font-size: 1rem;
	}
}

.about__title-container h1 br {
	@media screen and (max-width: 425px) {
		display: none;
	}
}

.about__hero-banner {
	width: 100%;
	@media screen and (min-width: 300px) {
		height: 30vh;
	}
	@media screen and (min-width: 420px) {
		height: 40vh;
	}
	@media screen and (min-width: 620px) {
		height: 55vh;
	}
	@media screen and (min-width: 887px) {
		height: 65vh;
	}
	@media screen and (min-width: 1181px) {
		height: 70vh;
	}
	@media screen and (min-width: 1200px) {
		height: 90vh;
	}
	@media screen and (min-width: 1400px) {
		height: 100vh;
	}
	@media screen and (min-width: 1920px) {
		height: 100vh;
	}
}

.about__hero-banner .hero__background {
	width: 100%;
	height: 100%;
	background: url("https://elasticbeanstalk-us-west-2-412710462953.s3-us-west-2.amazonaws.com/images/team-01.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
	@media screen and (max-width: 1024px) {
		background-size: cover;
		background-position: center;
	}
}

span.under__line {
	text-decoration: none;
	position: relative;
	cursor: default;
	color: #00584f;
}

.about__content-one {
	width: 100%;
	min-height: 75vh;
	background: #e6f2ee;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 3rem 0 3rem 0;
}

.about__content-wrap {
	width: 80%;
	height: 100%;
	padding: 3rem 0 3rem 0;
}

.about__content-container {
	margin-bottom: 4rem;
	width: 100%;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
	@media (min-width: 320px) and (max-width: 599px) {
		margin-bottom: 4rem;
	}
	@media (min-width: 599px) and (max-width: 1024px) {
		margin-bottom: 4rem;
	}
}

.about__content-container h1 {
	width: 60%;
	font-family: "Poppins", sans-serif;
	font-size: 2rem;
	color: #00584f;
	font-weight: 600;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 1.5rem;
		width: 100%;
	}
	@media (min-width: 599px) and (max-width: 1024px) {
		font-size: 1.75rem;
		width: 70%;
	}
	@media (min-width: 1024px) and (max-width: 1920) {
		font-size: 2rem;
		width: 65%;
	}
}

.about__content-container h1.mobile__spacing {
	@media screen and (max-width: 608px) {
		margin-bottom: 1rem;
	}
}

.add__text {
	width: 90%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
	@media screen and (max-width: 1024px) {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		margin-top: 1rem;
	}
	@media screen and (max-width: 425px) {
		margin-top: 1rem;
	}
}

.mobile__spacer {
	@media screen and (max-width: 1024px) {
		padding: 1rem 0 1rem 0;
	}
	@media screen and (max-width: 768px) {
		padding: 1rem 0 1rem 0;
	}
}

.add__text br {
	@media screen and (max-width: 1024px) {
		display: none;
	}
}

//come back and edit
.add__text h3 {
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
	font-weight: 400;
	letter-spacing: 0.5px;
	line-height: 2rem;
	color: #00584f;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 1.15rem;
		width: 80%;
	}
	@media (min-width: 599px) and (max-width: 1024px) {
		font-size: 1.15rem;
		width: 80%;
	}
	@media (min-width: 1024px) and (max-width: 1920) {
		font-size: 1.15em;
		width: 75%;
	}
}

.add__text h3.result {
	@media (min-width: 320px) and (max-width: 599px) {
		font-weight: 600;
	}
	@media (min-width: 599px) and (max-width: 1024px) {
		font-weight: 600;
	}
	@media (min-width: 1024px) and (max-width: 1920) {
		font-weight: 600;
	}
}

span.plus__sign {
	color: #00584f;
	font-weight: 600;
	font-size: 2.5rem;
	padding: 0 1rem 0 1rem;
	@media (min-width: 320px) and (max-width: 599px) {
		padding: 1rem;
	}
	@media (min-width: 599px) and (max-width: 1024px) {
		padding: 1rem;
	}
}

span.equal__sign {
	color: #00584f;
	font-weight: 600;
	font-size: 2.5rem;
	padding: 0 1rem 0 1rem;
	@media screen and (max-width: 1024px) {
		display: none;
	}
}

// profile section
.about__profile {
	width: 100%;
	height: 100%;
	background: white;
	height: 100%;
}

.about__profile-container {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 6%;
	padding-bottom: 6%;
}

.about__profile-wrapper {
	width: 80%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

.about__profile-title {
	width: 50%;
	margin-bottom: 6rem;
	@media (min-width: 320px) and (max-width: 599px) {
		padding-top: 2rem;
		width: 100%;
	}
	@media (min-width: 599px) and (max-width: 1024px) {
		padding-top: 2rem;
		width: 80%;
	}
	@media (min-width: 1024px) and (max-width: 1920) {
		padding-top: 2rem;
		width: 50%;
	}
}

.about__title-wrap {
	width: 80%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.about__profile-tag {
	width: 100%;
	margin-bottom: 3rem;
	display: flex;
	align-items: center;
}

.about__profile-tag h4 {
	color: black;
	text-transform: uppercase;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	letter-spacing: 0.05rem;
	font-size: 1.35rem;
	padding-left: 2rem;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 0.8rem;
	}
	@media only screen and (min-width: 620px) {
		font-size: 1.5vw;
	}
	@media only screen and (min-width: 768px) {
		font-size: 1.25vw;
	}
	@media only screen and (min-width: 920px) {
		font-size: 1vw;
	}
	@media only screen and (min-width: 1278px) {
		font-size: 0.7vw;
	}
	@media only screen and (min-width: 1920px) {
		font-size: 0.6vw;
	}
}

.about__profile-tag span {
	height: 0.1rem;
	background: black;
	width: 3rem;
}

.about__profile-title h1 {
	width: 100%;
	font-family: "Poppins", sans-serif;
	color: black;
	font-weight: 500;
	margin-bottom: 2rem;
	font-size: 2rem;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 1.5rem;
	}
	@media (min-width: 599px) and (max-width: 1024px) {
		font-size: 1.75rem;
	}
	@media (min-width: 1024px) and (max-width: 1920) {
		font-size: 2rem;
	}
}

.about__profile-title p {
	width: 100%;
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
	font-size: 1.35rem;
	color: black;
	line-height: 2rem;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 1.15rem;
	}
	@media (min-width: 599px) and (max-width: 1024px) {
		font-size: 1.15rem;
	}
	@media (min-width: 1024px) and (max-width: 1920) {
		font-size: 1.15rem;
	}
}

.about__profile-title a {
	font-size: 1.25rem;
	font-family: "Poppins", sans-serif;
	color: #000;
	text-decoration: none;
	font-weight: 600;
	@media screen and (max-width: 1024px) {
		font-size: 1rem;
	}
	@media screen and (max-width: 608px) {
		font-size: 0.75rem;
	}
}

span.arrow__left {
	margin-left: 2rem;
	font-size: 1.5rem;
	font-weight: 900;
	position: relative;
	padding: 1rem;
	transition: 1s;
}

span.arrow__left:hover {
	animation: bouncearrow 1s infinite;
	transition: 1s;
}

@keyframes bouncearrow {
	0% {
		left: 0px;
	}
	50% {
		left: 20px;
	}
	100% {
		left: 0px;
	}
}

// team section
.about__team-title {
	font-family: "Poppins", sans-serif;
	text-align: left;
	font-size: 2em;
}

// next three are equipped for mobile touch scroll across profiles

.about__team-wrapper {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	row-gap: 3rem;
	@media screen and (max-width: 1024px) {
		width: 100%;
		height: 100%;
		display: flex;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		scroll-snap-type: x mandatory;
	}
}

.about__team-hs {
	transition: ease-in-out all 0.3s;
	color: rgba(128, 128, 128, 0.7);
	@media screen and (max-width: 1024px) {
		flex-shrink: 0;
		width: 300px;
		height: 100%;
	}
}

.about__team-hs > div {
	@media screen and (max-width: 1024px) {
		scroll-snap-align: start;
	}
}

.about__team-hs.hover {
	-webkit-filter: grayscale(100%);
	-moz-filter: grayscale(100%);
	-ms-filter: grayscale(100%);
	-o-filter: grayscale(100%);
	filter: grayscale(100%);
	filter: rgba(128, 128, 128, 0.7); /* IE 6-9 */
	opacity: 0.8;
	transition: ease-in-out all 0.3s;
	-webkit-transition: ease-in-out all 0.3s;
	-moz-transition: ease-in-out all 0.3s;
	-ms-transition: ease-in-out all 0.3s;
	-o-transition: ease-in-out all 0.3s;
	transition: ease-in-out all 0.3s;
}

.about__team-hs img {
	width: 95%;
	padding: 0.5rem;
	padding-bottom: 1rem;
	@media screen and (max-width: 1024px) {
		width: 100%;
	}
}

.about__team-hs h2 {
	font-size: 1.15em;
	font-family: "Poppins", sans-serif;
	font-weight: 500;
	color: black;
	padding-left: 0.5rem;
	margin-bottom: -0.15rem;
	@media screen and (max-width: 1024px) {
		font-size: 1.25rem;
	}
}

.about__team-hs h3 {
	font-size: 1.15em;
	font-family: "Poppins", sans-serif;
	font-weight: 500;
	padding-left: 0.5rem;
	line-height: 1.50rem;
	@media screen and (max-width: 1024px) {
		font-size: 1rem;
		color: #3ea89a;
	}
}

.about__team-hs:hover,
.about__team-hs h3:hover {
	color: #3ea89a;
}

span.heading__stroke-about {
	-webkit-text-stroke: 1.5px black;
	color: transparent;
}

// about temp section
.about__temp-section {
	width: 100%;
	height: 100%;
	background: white;
	display: flex;
	justify-content: center;
	align-items: center;
}

.temp__border {
	width: 80%;
	height: 2px;
	background: lightgrey;
	margin-top: 4rem;
	margin-bottom: 8rem;
}

// about studio section
.about__studio-header {
	width: 100%;
	height: 100%;
	padding-bottom: 10rem;
	background: white;
}

.studio__title-container {
	width: 80%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin: 0 auto;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

.studio__title-main {
	width: 75%;
	margin-bottom: 10rem;
	@media screen and (max-width: 1024px) {
		width: 80%;
	}
	@media screen and (max-width: 608px) {
		width: 100%;
	}
}

.about__studio-wrap {
	width: 80%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.about__studio-tag {
	width: 100%;
	margin-bottom: 3rem;
	display: flex;
	align-items: center;
}

.about__studio-tag h4 {
	color: black;
	text-transform: uppercase;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	letter-spacing: 0.05rem;
	padding-left: 2rem;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 0.8rem;
	}
	@media only screen and (min-width: 620px) {
		font-size: 1.5vw;
	}
	@media only screen and (min-width: 768px) {
		font-size: 1.25vw;
	}
	@media only screen and (min-width: 920px) {
		font-size: 1vw;
	}
	@media only screen and (min-width: 1278px) {
		font-size: 0.7vw;
	}
	@media only screen and (min-width: 1920px) {
		font-size: 0.6vw;
	}
}

.about__studio-tag span {
	height: 0.1rem;
	background: black;
	width: 3rem;
}

.studio__title-main h1 {
	width: 100%;
	font-family: "Poppins", sans-serif;
	color: black;
	font-weight: 500;
	font-size: 2rem;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 1.5rem;
		width: 80%;
	}
	@media (min-width: 599px) and (max-width: 1024px) {
		font-size: 1.75rem;
		width: 80%;
	}
	@media (min-width: 1024px) and (max-width: 1920) {
		font-size: 2rem;
		width: 80%;
	}
}

.studio__title-main .studio__text-container {
	width: 100%;
	-webkit-column-count: 2; /* Old Chrome, Safari and Opera */
	-moz-column-count: 2; /* Old Firefox */
	column-count: 2;
	column-gap: 2rem;
	@media screen and (max-width: 1440px) {
		-webkit-column-count: 1; /* Old Chrome, Safari and Opera */
		-moz-column-count: 1; /* Old Firefox */
	}
}

.studio__title-main .studio__text-container p {
	width: 100%;
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
	font-size: 1.35rem;
	color: black;
	line-height: 2rem;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 1.15rem;
	}
	@media (min-width: 599px) and (max-width: 1024px) {
		font-size: 1.15rem;
	}
	@media (min-width: 1024px) and (max-width: 1920) {
		font-size: 1.15rem;
	}
}

// NOT USED START

.studio__title-main p span.moretext-studio {
	display: none;
}

.studio__title-main span.moreless-studio {
	position: relative;
	display: block;
	cursor: pointer;
	text-decoration: none;
	font-size: 1em;
	font-family: "Poppins", sans-serif;
	color: black;
	transition: 0.2s;
	text-align: left;
}

.studio__title-main span.moreless-studio:before,
span.moreless-studio:after {
	content: '';
	position: absolute;
	width: 0%;
	height: 2px;
	top: 50%;
	margin-top: -0.5px;
	background: #000000;
}

.studio__title-main span.moreless-studio:before {
	left: -2.5px;
}

.studio__title-main span.moreless-studio:after {
	right: 2.5px;
	background: #000000;
	transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.studio__title-main span.moreless-studio:hover:before {
	background: #000000;
	width: 100%;
	transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.studio__title-main span.moreless-studio:hover:after {
	background: transparent;
	width: 100%;
	transition: 0s;
}

// NOT USED END

.office__images {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.image__gallery {
	width: 80%;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

.image__gallery-grid {
	display: grid;
	grid-template-columns: repeat(16, 1fr);
	grid-gap: 15px;
}

.image__gallery-grid img {
	width: 100%;
	height: 99.6%;
	object-fit: cover;
}

figure.img-07 {
	grid-column-start: 1;
	grid-column-end: 10;
}

figure.img-03 {
	grid-column-start: 10;
	grid-column-end: 17;
}

figure.img-02 {
	grid-column-start: 1;
	grid-column-end: 8;
}

figure.img-06 {
	grid-column-start: 8;
	grid-column-end: 17;
}

figure.img-04 {
	grid-column-start: 1;
	grid-column-end: 7;
}

figure.img-09 {
	grid-column-start: 7;
	grid-column-end: 12;
}

figure.img-05 {
	grid-column-start: 12;
	grid-column-end: 17;
}

figure.img-10 {
	grid-column-start: 1;
	grid-column-end: 10;
}

figure.img-08 {
	grid-column-start: 10;
	grid-column-end: 17;
}
