@font-face {
	font-family: 'TIMMONS_NY_FINAL';
	src: url('../fonts/TIMMONS_NY_FINAL.woff') format('woff');
}

@import '0-abstracts/variables';
@import '0-abstracts/mixins';
@import '0-abstracts/breakpoints';
@import '1-basics/reset';
@import '2-components/effects';
@import '2-components/layers';
@import '2-components/slider';
@import '2-components/preloader';
@import '3-layout/nav';
@import '3-layout/footer';
@import '4-pages/about';
@import '4-pages/home';
@import '4-pages/approach';
@import '4-pages/404';
@import '4-pages/privacy';
@import '4-pages/dispatches';
@import '4-pages/field-reports.scss';
@import '4-pages/contact.scss';