.post-disp {
    width: 100%;
    height: 100%;
    background: white;
    transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

.post__wrapper-disp {
    width: 100%;
    height: 100%;
}

.post__hero-disp {
    width: 100%;
    height: 35vh;
    background: lightgrey;
    @media screen and (min-width: 1024px) {
        height: 65vh;
    }
}

.post__hero-disp img {
    width: 100%;
    height: 35vh;
    object-fit: cover;
    object-position: center;
    @media screen and (min-width: 1024px) {
        height: 65vh;
    }
}

.post__content-wrapper-disp {
    width: 80%;
    height: 100%;
    padding-bottom: 2rem;
    background: white;
    margin: 0 auto;
    @media screen and (min-width: 768px) {
        width: 80%;
        margin: 0 auto;
        padding-bottom: 6rem;
        margin-top: 2rem;
        padding-left: 0;
    }
    @media screen and (min-width: 1024px) {
        width: 80%;
        margin: 0 auto;
        padding-bottom: 6rem;
        padding-left: 0;
    }
    @media screen and (min-width: 1278px) {
        width: 50%;
        margin: 0 auto;
        padding-bottom: 6rem;
        padding-left: 0;
    }
}

.post__title-container-disp {
    @media screen and (min-width: 1024px) {
        display: flex;
        padding-top: 7rem;
    }
}

.post__title-content-disp {
    width: 100%;
}

.field-tag-disp {
	width: 100%;
	margin-bottom: 2rem;
	display: flex;
    align-items: center;
    margin-top: 4rem;
    @media screen and (min-width: 768px) {
        margin-top: 0;
    }
}

.field-tag-disp h4 {
	padding-left: 1.5rem;
}

.field-tag-disp span {
	height: 0.1rem;
	background: black;
	width: 3rem;
}

.post-disp h1 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: #279989;
    font-size: 3rem;
    line-height: 3.25rem;
    @media screen and (min-width: 1024px) {
        font-size: 3.5rem;
        line-height: 5.5rem;
        width: 80%;
    }
}

.post__subtitle-disp {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.post-disp h2 {
    font-family: "Neue Haas Grotesk Text Pro", sans-serif;
    color: #939598;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 300;
    @media screen and (min-width: 1024px) {
        font-size: 1.75rem;
        line-height: 2.25rem;
        width: 100%;
    }
}

.post-disp h4 {
    color: #939598;
	text-transform: uppercase;
	font-family: 'Poppins', sans-serif;
	font-weight: 300;
	letter-spacing: 0.05rem;
	font-size: 0.75rem;
}

.post-disp h4.content-header-two-disp {
    color: black;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 0.05rem;
    font-size: 1.25rem;
}

.post-disp h4.date {
    color: black;
}

.post-disp h3 {
    color: black;
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	letter-spacing: 0.05rem;
    font-size: 1rem;
}

.post-disp h3.content-header-disp {
    color: black;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 0.05rem;
    font-size: 1.75rem;
    margin-bottom: 0rem;
}

.post-disp h5 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 0.8rem;
    color: black;
    padding-right: 2rem;
}

.post-disp h5.sources {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 1rem;
    color: black;
    padding-right: 2rem;
}

.post-disp h6 {
    color: black;
	font-family: 'Poppins', sans-serif;
	font-weight: 300;
	letter-spacing: 0.05rem;
	font-size: 0.75rem;
}

.post-disp h6.img-title-disp {
    color: #939598;
    text-transform: uppercase;
}

.post-disp h6.img-desc-disp {
    color: black;
    text-transform: none;
    padding-top: 0.5rem;
    font-style: italic;
}

.post__contributors-disp {
    padding-bottom: 2rem;
    display: flex;
    align-items: center;
    @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
        padding-bottom: 2rem;
    }
}

.post__contributors-disp h4.contributors-disp {
    padding-left: 1rem;
    color: black;
    @media screen and (min-width: 768px) {
        padding-top: 0;
        padding-left: 1rem;
    }
}

.post__quote {
    padding-bottom: 5rem;
}

.post__section-quote-disp blockquote {
    width: 100%;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: #2A4549;
    font-style: italic;
    font-size: 1.55rem;
    line-height: 2rem;
    padding-left: 2rem;
    @media screen and (min-width: 1024px) {
        font-size: 2.25rem;
        line-height: 3rem;
    }
}

.post-disp p {
    font-family: "Neue Haas Grotesk Text Pro", sans-serif;
    font-size: 1rem;
    line-height: 1.75rem;
    @media screen and (min-width: 1024px) {
        width: 100%;
        margin: 0 auto;
        font-size: 1.25rem;
        line-height: 2rem;
    }
}

.post-disp ol li.list-source {
    font-family: "Neue Haas Grotesk Text Pro", sans-serif;
    font-size: 0.75rem;
    line-height: 1.75rem;
    font-style: italic;
    @media screen and (min-width: 1024px) {
        width: 100%;
        margin: 0 auto;
        font-size: 0.8rem;
        line-height: 2rem;
        font-size: 1rem;
    }
}

ol.list-items-sources {
    padding-left: 1rem;
}
// temp img placeholder
.post__img-disp {
    width: 100%;
    height: 35vh;
    display: flex;
    justify-content: center;
    @media screen and (min-width: 768px) {
        margin-top: 4rem;
        width: 100%;
        margin: 0 auto;
        height: 75vh;
    }
}

.post__img-disp img {
    width: 80%;
    height: 35vh;
    object-fit: cover;
    object-position: center;
    @media screen and (min-width: 768px) {
        height: 75vh;
    }
}

.post__img-full-disp {
    width: 100%;
    height: 50vh;
    background: lightgrey;
    @media screen and (min-width: 1024px) {
        width: 100%;
        height: 65vh;
        margin-bottom: 0.5rem;
    }
}

.post__img-credit-disp {
    padding-left: 0;
    padding-bottom: 5rem;
    padding-top: 1rem;
    @media screen and (min-width: 768px) {
        padding-left: 0;
    }
    @media screen and (min-width: 1024px) {
        padding-left: 0;
        padding-bottom: 6rem;
    }
}

.post__border-quote-disp {
    padding-bottom: 2rem;
    width: 80%;
    margin: 0 auto;
    @media screen and (min-width: 768px) {
        width: 75%;
        margin: 0 auto;
    }
    @media screen and (min-width: 1024px) {
        width: 65%;
        margin: 0 auto;
    }
}

.post__section-quote-disp {
    border-left: 2px solid #2A4549;
}

.post__section-quote-author-disp {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
}

.post__section-quote-author-disp span.title {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 0.7rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    color: #939598;
    padding-left: 2rem;
    @media screen and (min-width: 1024px) {
        width: 100%;
    }
}

ul.list, ol.list {
    padding-left: 2.5rem;
    @media screen and (min-width: 768px) {
        padding: 0;
    }
}

ul.list li {
    font-family: "Neue Haas Grotesk Text Pro", sans-serif;
    font-size: 1rem;
    line-height: 1.75rem;
    @media screen and (min-width: 728px) {
        margin-left: 4rem;
    }
    @media screen and (min-width: 1024px) {
        margin: 0 auto;
        font-size: 1.25rem;
        line-height: 2rem;
        margin-left: 4rem;
        padding-top: 2rem;
    }
}

ol.list li {
    font-family: "Neue Haas Grotesk Text Pro", sans-serif;
    font-size: 1rem;
    line-height: 1.75rem;
    @media screen and (min-width: 728px) {
        margin-left: 4rem;
    }
    @media screen and (min-width: 1024px) {
        margin: 0 auto;
        font-size: 1.25rem;
        line-height: 2rem;
        margin-left: 4rem;
        padding-top: 2rem;
    }
}

.post__divider-disp {
    height: 2px;
    background: lightgrey;
    width: 90%;
    margin: 0 auto;
    margin-top: 2rem;
    margin-bottom: 5rem;
    @media screen and (min-width: 768px) {
        width: 80%;
        margin-bottom: 5rem;
    }
    @media screen and (min-width: 1024px) {
        width: 80%;
        margin: 0 auto;
        margin-top: 2rem;
        margin-bottom: 4rem;
    }
    @media screen and (min-width: 1278px) {
        width: 60%;
    }
}

.post__divider-disp-two {
    height: 2px;
    background: lightgrey;
    width: 90%;
    margin-bottom: 0.5rem;
    margin: 0 auto;
    @media screen and (min-width: 1024px) {
        width: 80%;
        margin: 0 auto;
        margin-top: 2rem;
        margin-bottom: 4rem;
    }
}

.post__portrait-container-disp {
    display: flex;
    width: 80%;
    margin: 0 auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
    @media screen and (min-width: 768px){
        align-content: center;
        margin: 0 auto;
        padding-bottom: 4rem;
    }
}

.post__portrait-disp {
    border-radius: 50%;
    margin-top: 0;
    margin-bottom: 20px;
    height: 80px;
    width: 80px;
    overflow: hidden;
    background: lightgrey;
    @media screen and (min-width: 768px) {
        height: 115px;
        width: 115px;
    }
}

.post__portrait-disp img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: block;
}

.post__portrait-description-disp {
    padding-left: 1rem;
    width: 70%;
    @media screen and (min-width: 768px) {
        padding-left: 2rem;
        width: 50%;
    }
}

.post-disp h3.contributor-disp {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 1.25rem;
    color: black;
    @media screen and (min-width: 1024px) {
        font-size: 1.5rem;
    }
}

.post-disp h4.title-disp {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 0.75rem;
    color: #279989;
    margin-bottom: 0.5rem;
    @media screen and (min-width: 1024px) {
        font-size: 1rem;
    }
}

.post-disp span.bio-disp {
    font-family: "neue-haas-grotesk-text-pro", sans-serif;
    font-weight: 300;
    font-size: 0.7rem;
    color: black;
    line-height: 1.25rem;
    @media screen and (min-width: 1024px) {
        margin: 0;
        font-size: 1rem;
        line-height: 1.50rem;
    }
}

.cta--a {
    width: 100%;
    height: 65vh;
    background: #2A4549;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cta--a .list_random .cta--a-wrapper {
    width: 80%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;

}

.cta--a .list_random .cta--a-wrapper h1 {
    font-family: "poppins", sans-serif;
    font-weight: 500;
    color: white;
    font-size: 2.75rem;
    @media screen and (max-width: 1024px) {
        font-size: 2.5rem;
    }
    @media screen and (max-width: 599px) {
        font-size: 1.5rem;
    }
}

.disp__section-social {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 4rem;
    padding-top: 2rem;
    @media screen and (min-width: 1024px) {
        display: none;
    }
}

.disp__section-social .disp__section-social-title p {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 0.8rem;
    color: black;
    padding-right: 2rem;
}

.disp__section-social .disp__section-social-icons a {
    text-decoration: none;
}

.disp__section-social-icons svg {
    width: 10%;
    margin-right: 1rem;
    @media screen and (min-width: 768px){
        width: 5%;
    }
    @media screen and (min-width: 1024px){
        width: 3.5%;
    }
}

.disp__section-social-icons svg path.a {
    fill: #ffffff;
}

span#status p.article_success {
    width: 470px;
    margin: 10px 0;
    padding: 10px;
    border-radius: 3px 3px 3px 3px;
    color: #279989;
    background-color: white;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400px;
}

span#status p.article_error {
    width: 470px;
    margin: 10px 0;
    padding: 10px;
    border-radius: 3px 3px 3px 3px;
    color: #D8000C;
    background-color: #FFBABA;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400px;
}