


// found@nav & home
@mixin full__sizing {
    width: 100%;
    height: 100vh;
}

@mixin layer__nine__basics {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: relative;
    bottom: 60vh;
}
