* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	border: 0;
	outline: 0;
	vertical-align: baseline;
}

html,
body {
	margin: 0;
	padding: 0;
}

html {
	font-size: 16px;
}

// index.js/about.js has scroll__lock script -> width + overflow-x value below prevent body content movement when scroll appears after slider is activated
body {
	background: #20393e;
	width: 100vw;
	overflow-x: hidden;
}

.clearfix:after {
	display: block;
	content: " ";
	clear: both;
}

.main__content-wrapper {
	width: 100%;
}

::selection {
	background: #3EA89A; /* WebKit/Blink Browsers */
  }
  ::-moz-selection {
	background: #3EA89A; /* Gecko Browsers */
  }


