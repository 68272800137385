.preloader {
    width: 100%;
    height: 100vh;
    background: #49A89A;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 10000;
    overflow-x: hidden;
  }

.preloader.hidden {
    animation: fadeOut 1s;
    animation-fill-mode: forwards;
    width: 100%;
    height: 100vh;
    display: flex; 
    justify-content: center;
    align-content: center;
}

  @keyframes fadeOut {
      100% {
          opacity: 0;
          visibility: hidden;
      }
  }

  .path {
    stroke-dasharray: 600;
    stroke-dashoffset: 600;
    animation: dash 3s linear alternate infinite;
  }
  
  @keyframes dash {
    from {
      stroke-dashoffset: 600;
    }
    to {
      stroke-dashoffset: 0;
    }
  }