.approach__full-wrapper {
	width: 100%;
}

.approach__header {
	width: 100%;
	min-height: 100vh;
	background: #0a2b2b;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

.approach__hero {
	width: 100%;
	min-height: 100vh;
	padding-top: 5rem;
	background: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
}

.approach__title-wrap {
	width: 80%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	position: relative;
	top: 5rem;
	@media only screen and (max-width: 840px) and (orientation: landscape) {
		position: relative;
		top: 50px;
		left: 50px;
	}
}

.approach__title-tag {
	width: 100%;
	margin-bottom: 3rem;
	display: flex;
	align-items: center;
}

.approach__title-tag h4 {
	color: white;
	text-transform: uppercase;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	letter-spacing: 0.05rem;
	font-size: 1.35rem;
	padding-left: 2rem;
	@media (min-width: 320px) and (max-width: 619px) {
		font-size: 0.8rem;
	}
	@media only screen and (min-width: 620px) {
		font-size: 1.5vw;
	}
	@media only screen and (min-width: 768px) {
		font-size: 1.25vw;
	}
	@media only screen and (min-width: 920px) {
		font-size: 1vw;
	}
	@media only screen and (min-width: 1278px) {
		font-size: 0.7vw;
	}
	@media only screen and (min-width: 1920px) {
		font-size: 0.6vw;
	}
	@media only screen and (max-width: 840px) and (orientation: landscape) {
		font-size: 1.5vw;
	}
}

.approach__title-tag span {
	height: 0.1rem;
	background: white;
	width: 3rem;
}

.approach__title-container {
	width: 100%;
	height: 100%;
}

.approach__title-container h1 {
	font-family: "Poppins", sans-serif;
	font-size: 3rem;
	color: white;
	font-weight: 600;
	letter-spacing: 0.15rem;
	margin-bottom: 1rem;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 2rem;
	}
	@media (min-width: 599px) and (max-width: 1024px) {
		font-size: 2.5rem;
	}
	@media (min-width: 1024px) and (max-width: 1920px) {
		font-size: 3rem;
		line-height: 3.75rem;
	}
	@media only screen and (max-width: 840px) and (orientation: landscape) {
		font-size: 1rem;
	}
}

.approach__title-container p {
	width: 55%;
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
	color: white;
	line-height: 1.75rem;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 1.15rem;
		width: 100%;
	}
	@media (min-width: 599px) and (max-width: 1024px) {
		font-size: 1.15rem;
		width: 80%;
	}
	@media (min-width: 1024px) and (max-width: 1920) {
		font-size: 1.15rem;
		width: 55%;
	}
}

// intention section
.approach__intention {
	width: 100%;
	min-height: 100vh;
	background: url("https://elasticbeanstalk-us-west-2-412710462953.s3-us-west-2.amazonaws.com/images/intention.jpg");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	justify-content: flex-end;
	/* flip background vertically */
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
	@media screen and (max-width: 768px) {
		min-height: 50vh;
	}
}

.approach__intention-overlay {
	width: 50%;
	height: 100%;
	background: rgba(10, 43, 43, 0.8);
	@media screen and (max-width: 1023px) {
		display: none;
	}
}

.approach__intention-overlay-mobile {
	width: 100%;
	min-height: 100vh;
	background: #0a2b2b;
	@media screen and (min-width: 1023px) {
		display: none;
	}
}

.approach__intention-content {
	width: 90%;
	min-height: 100vh;
	padding: 5rem 0 5rem 5rem;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	@media screen and (max-width: 425px) {
		padding: 3rem;
	}
	@media screen and (max-width: 425px) {
		padding: 3rem;
	}
}

.approach__intention-content h3 {
	font-family: "Poppins", sans-serif;
	font-size: 16px;
	color: white;
	font-weight: 300;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 0.75rem;
		margin-bottom: 5rem;
	}
}

.approach__intention-content h1 {
	font-family: "Poppins", sans-serif;
	font-weight: 500;
	font-size: 2.5rem;
	color: white;
	margin-bottom: 0.5rem;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 2.5rem;
	}
	@media (min-width: 599px) and (max-width: 1024px) {
		font-size: 3rem;
	}
	@media (min-width: 1024px) and (max-width: 1920px) {
		font-size: 3.5rem;
	}
}

.approach__intention-content p {
	font-size: 1rem;
	color: white;
	line-height: 1.50rem;
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 1rem;
	}
	@media (min-width: 599px) and (max-width: 1024px) {
		font-size: 1rem;
	}
	@media (min-width: 1024px) and (max-width: 1920px) {
		font-size: 1rem;
	}
}

.approach__strategy {
	width: 100%;
	min-height: 100vh;
	background: url("https://elasticbeanstalk-us-west-2-412710462953.s3-us-west-2.amazonaws.com/images/strategy.jpg");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	justify-content: flex-start;
	/* flip background vertically */
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
	@media screen and (max-width: 768px) {
		min-height: 50vh;
	}
}

.approach__strategy-overlay {
	width: 50.10%;
	height: 100%;
	background: rgba(10, 43, 43, 0.8);
	display: flex;
	justify-content: flex-end;
	@media screen and (max-width: 1023px) {
		display: none;
	}
}

.approach__strategy-content {
	width: 90%;
	min-height: 100vh;
	padding: 5rem 5rem 5rem 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.approach__strategy-content h3 {
	font-family: "Poppins", sans-serif;
	font-size: 16px;
	color: white;
	font-weight: 300;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 0.75rem;
	}
}

.approach__strategy-content h1 {
	font-family: "Poppins", sans-serif;
	font-weight: 500;
	font-size: 2.5rem;
	color: white;
	margin-bottom: 0.5rem;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 2.5rem;
	}
	@media (min-width: 599px) and (max-width: 1024px) {
		font-size: 3rem;
	}
	@media (min-width: 1024px) and (max-width: 1920px) {
		font-size: 3.5rem;
	}
}

.approach__strategy-content p {
	width: 100%;
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
	font-weight: 300;
	font-size: 1rem;
	line-height: 1.5rem;
	color: white;
}

.approach__tactics {
	width: 100%;
	min-height: 100vh;
	background: url("https://elasticbeanstalk-us-west-2-412710462953.s3-us-west-2.amazonaws.com/images/tactics.jpg");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	justify-content: flex-end;
	/* flip background vertically */
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
	@media screen and (max-width: 768px) {
		min-height: 50vh;
	}
}

.approach__title-container span.remove__br {
	@media screen and (max-width: 1440px) {
		display: none;
	}
}

.approach__tactics-overlay {
	width: 50%;
	height: 100%;
	background: rgba(10, 43, 43, 0.8);
	@media screen and (max-width: 1023px) {
		display: none;
	}
}

.approach__tactics-content {
	width: 90%;
	min-height: 100vh;
	padding: 5rem 0 5rem 5rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

.approach__tactics-content h3 {
	font-family: "Poppins", sans-serif;
	font-size: 16px;
	color: white;
	font-weight: 300;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 0.75rem;
	}
}

.approach__tactics-content h1 {
	font-family: "Poppins", sans-serif;
	font-weight: 500;
	font-size: 2.5rem;
	color: white;
	margin-bottom: 0.5rem;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 2.5rem;
	}
	@media (min-width: 599px) and (max-width: 1024px) {
		font-size: 3rem;
	}
	@media (min-width: 1024px) and (max-width: 1920px) {
		font-size: 3.5rem;
	}
}

.approach__tactics-content p {
	width: 100%;
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
	font-weight: 300;
	font-size: 1rem;
	line-height: 1.5rem;
	color: white;
}

.approach__action {
	width: 100%;
	min-height: 100vh;
	background: url("https://elasticbeanstalk-us-west-2-412710462953.s3-us-west-2.amazonaws.com/images/action.jpg");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	justify-content: flex-start;
	/* flip background vertically */
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
	@media screen and (max-width: 768px) {
		min-height: 50vh;
	}
}

.approach__action-overlay {
	width: 50.10%;
	height: 100%;
	background: rgba(10, 43, 43, 0.8);
	display: flex;
	justify-content: flex-end;
	@media screen and (max-width: 1023px) {
		display: none;
	}
}

.approach__action-content {
	width: 90%;
	min-height: 100vh;
	padding: 5rem 5rem 5rem 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.approach__action-content h3 {
	font-family: "Poppins", sans-serif;
	font-size: 16px;
	color: white;
	font-weight: 300;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 0.75rem;
	}
}

.approach__action-content h1 {
	font-family: "Poppins", sans-serif;
	font-weight: 500;
	font-size: 2.5rem;
	color: white;
	margin-bottom: 0.5rem;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 2.5rem;
	}
	@media (min-width: 599px) and (max-width: 1024px) {
		font-size: 3rem;
	}
	@media (min-width: 1024px) and (max-width: 1920px) {
		font-size: 3.5rem;
	}
}

.approach__action-content p {
	width: 100%;
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
	font-weight: 300;
	font-size: 1rem;
	line-height: 1.5rem;
	color: white;
}

.highlight__content {
	color: #3ea89a;
	font-weight: bold;
	font-size: 1rem;
}

.read-title {
	display: none;
}

span.read-button-title {
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
	font-weight: 300;
	font-size: 1rem;
	line-height: 1.5rem;
	color: white;
	cursor: pointer;
}

.read-intention {
	display: none;
}

span.read-button-intention {
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
	font-weight: 300;
	font-size: 1rem;
	line-height: 1.5rem;
	color: white;
	cursor: pointer;
}

.read-intention-mobile {
	display: none;
}

span.read-button-intention-mobile {
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
	font-weight: 300;
	font-size: 1rem;
	line-height: 1.5rem;
	color: white;
	cursor: pointer;
}

.read-strategy {
	display: none;
}

span.read-button-strategy {
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
	font-weight: 300;
	font-size: 1rem;
	line-height: 1.5rem;
	color: white;
	cursor: pointer;
}

.read-strategy-mobile {
	display: none;
}

span.read-button-strategy-mobile {
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
	font-weight: 300;
	font-size: 1rem;
	line-height: 1.5rem;
	color: white;
	cursor: pointer;
}

.read-tactics {
	display: none;
}

span.read-button-tactics {
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
	font-weight: 300;
	font-size: 1rem;
	line-height: 1.5rem;
	color: white;
	cursor: pointer;
}

.read-tactics-mobile {
	display: none;
}

span.read-button-tactics-mobile {
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
	font-weight: 300;
	font-size: 1rem;
	line-height: 1.5rem;
	color: white;
	cursor: pointer;
}

.read-action {
	display: none;
}

span.read-button-action {
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
	font-weight: 300;
	font-size: 1rem;
	line-height: 1.5rem;
	color: white;
	cursor: pointer;
}

span.read-button-action-mobile {
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
	font-weight: 300;
	font-size: 1rem;
	line-height: 1.5rem;
	color: white;
	cursor: pointer;
}

