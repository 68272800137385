#main__header-hero {
	overflow: hidden;
	position: relative;
	@media only screen and (max-width: 1024px) {
		display: none;
	}
}

#main__header-content {
	background-color: #2a454a;
	height: 100%;
	padding-bottom: 1rem;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
	@media only screen and (max-width: 1024px) {
		display: none;
	}
}

.main__header-body {
	width: 80%;
	position: relative;
	top: 15rem;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
	z-index: 4;
	@media only screen and (max-width: 425px) {
		width: 80%;
		top: 0;
	}
}

// test
.home__header-tag {
	width: 100%;
	margin-bottom: 3rem;
	display: flex;
	align-items: center;
}

.home__header-tag h4 {
	color: white;
	text-transform: uppercase;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	letter-spacing: 0.05rem;
	font-size: 1rem;
	padding-left: 2rem;
	@media only screen and (max-width: 565px) {
		font-size: 0.8rem;
	}
}

.home__header-tag span {
	height: 0.2rem;
	background: white;
	width: 3rem;
}

.main__header-body h1 {
	font-family: "Poppins", sans-serif;
	color: white;
	font-size: 2.5vw;
	font-weight: 600;
	@media only screen and (min-width: 250px) {
		font-size: 7vw;
		width: 100%;
	}
	@media only screen and (min-width: 320px) {
		font-size: 6.5vw;
		width: 100%;
	}
	@media only screen and (min-width: 422px) {
		font-size: 6vw;
		width: 100%;
	}
	@media only screen and (min-width: 620px) {
		font-size: 5vw;
		width: 100%;
	}
	@media only screen and (min-width: 768px) {
		font-size: 4.5vw;
		width: 90%;
	}
	@media only screen and (min-width: 920px) {
		font-size: 2.5vw;
		width: 70%;
	}
	@media only screen and (min-width: 1278px) {
		font-size: 2.5vw;
		width: 60%;
	}
	@media only screen and (min-width: 1920px) {
		font-size: 2.5vw;
		width: 50%;
	}
}

.main__header-body h1 br {
	@media only screen and (max-width: 1320px) {
		display: none;
	}
}

.main__header-call {
	width: 40%;
	padding-top: 0.5rem;
	@media only screen and (min-width: 1278px) {
		width: 35%;
	}
}

.main__header-body .main__header-call h2 {
	width: 70%;
	padding-bottom: 1.5rem;
	font-family: "Poppins", sans-serif;
	color: #279989;
	font-size: 1.25vw;
	font-weight: 600;
}

.main__header-body .main__header-call a.drive__btn {
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
	font-size: 0.75rem;
	font-weight: bold;
	color: white;
	text-decoration: none;
	text-transform: uppercase;
	border: 1px solid white;
	padding: 0.75rem;
	position: relative;
	top: 40px;
}

.layer {
	background-position: bottom center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100vw;
	height: 100vh;
	position: fixed;
	z-index: -1;
}

.main__header-section {
	position: relative;
	height: 50vh;
}

#main__header-hero,
.layer {
	min-height: 100vh;
}

.layer-bg {
	background-image: url("https://elasticbeanstalk-us-west-2-412710462953.s3-us-west-2.amazonaws.com/images/layer__bg__1920.png");
	background-size: cover;
}

.layer-1 {
	background-image: url("https://elasticbeanstalk-us-west-2-412710462953.s3-us-west-2.amazonaws.com/images/layer__1__1920.png");
	background-size: cover;
	margin-top: 4em;
}

.layer-2 {
	background-image: url("https://elasticbeanstalk-us-west-2-412710462953.s3-us-west-2.amazonaws.com/images/layer__2__1920.png");
	background-size: cover;
	margin-top: 4em;
	animation: move linear 250s infinite;
	@keyframes move {
		from {
			right: -20%;
		}
		to {
			right: 100%;
		}
	}
}

.layer-3 {
	background-image: url("https://elasticbeanstalk-us-west-2-412710462953.s3-us-west-2.amazonaws.com/images/layer__3__1920.png");
	background-size: cover;
	margin-top: 4em;
	animation: move linear 250s infinite;
	@keyframes move {
		from {
			right: 0%;
		}
		to {
			right: 100%;
		}
	}
	@media only screen and (max-width: 1024px) {
		display: none;
	}
}

.layer-4 {
	background-image: url("https://elasticbeanstalk-us-west-2-412710462953.s3-us-west-2.amazonaws.com/images/layer__4__1920.png");
	background-size: cover;
	margin-top: 4em;
}

.main__hero-heading {
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	height: 55%;
	color: white;
	cursor: default;
}

.main__hero-heading h1 {
	font-family: 'TIMMONS_NY_FINAL', serif;
	font-size: 15vw;
	text-transform: uppercase;
	font-weight: normal;
}

.main__hero-heading-mobile h1 {
	font-family: 'TIMMONS_NY_FINAL', serif;
	font-size: 25vw;
	text-transform: uppercase;
	text-align: center;
	font-weight: normal;
}

.main__hero-heading h1 span.lower__case {
	text-transform: lowercase;
}

.main__hero-heading h1 span.heading__stroke {
	-webkit-text-stroke: 2px white;
	letter-spacing: 5px;
	color: transparent;
}

.main__hero-heading-mobile h1 span.lower__case {
	text-transform: lowercase;
}

.main__hero-heading-mobile h1 span.heading__stroke {
	-webkit-text-stroke: 2px white;
	letter-spacing: 5px;
	color: transparent;
}

.layer-5 {
	background-image: url("https://elasticbeanstalk-us-west-2-412710462953.s3-us-west-2.amazonaws.com/images/layer__5__1920.png");
	background-size: cover;
	margin-top: 4em;
	animation: move linear 250s infinite;
	@keyframes move {
		from {
			right: -20;
		}
		to {
			right: 100%;
		}
	}
}

.layer-6 {
	background-image: url("https://elasticbeanstalk-us-west-2-412710462953.s3-us-west-2.amazonaws.com/images/layer__6__1920.png");
	background-size: cover;
	margin-top: 4em;
}

.layer-7 {
	background-image: url("https://elasticbeanstalk-us-west-2-412710462953.s3-us-west-2.amazonaws.com/images/layer__7__1920.png");
	background-size: cover;
	margin-top: 4em;
}

.layer-8 {
	background-image: url("https://elasticbeanstalk-us-west-2-412710462953.s3-us-west-2.amazonaws.com/images/layer__8__1920.png");
	background-size: cover;
	margin-top: 4em;
	animation: move linear 280s infinite;
	@keyframes move {
		from {
			right: 0%;
		}
		to {
			right: 100%;
		}
	}
}

.layer-overlay {
	background-image: url("https://elasticbeanstalk-us-west-2-412710462953.s3-us-west-2.amazonaws.com/images/layer__9_1920.png");
	width: 100%;
	position: relative;
	z-index: 1;
	bottom: 45em;
	min-height: 1280px;
	background-position: 100% 80%;
	@media screen and (max-width: 1024px) {
		display: none;
	}
	@media screen and (max-width: 1440px) {
		bottom: 48em;
	}
}

//CALL TO ACTION STARTS HERE

.main__hero-cta-wrapper {
	margin-top: 20em;
	width: 100vw;
	height: 100%;
	display: flex;
	justify-content: center;
	z-index: 3;
}

.main__hero-cta {
	width: 59.5%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
	height: 50%;
	color: white;
	cursor: default;
}

.main__hero-cta p {
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
	font-weight: 400;
	font-size: 1.35rem;
	line-height: 2rem;
	margin-bottom: 1.25em;
	@media only screen and (max-width: 1024px) {
		font-size: 1.10rem;
	}
}

//HIDE MOBILE ABOVE 601px

@media only screen and (min-width: 1025px) {
	.mobile__header {
		display: none;
	}
	.mobile__header-content {
		display: none;
	}
}

@media (min-width: 599px) and (max-width: 1024px) {
	#main__header-hero {
		display: none;
	}
	.business-tag {
		display: none;
	}

	.mobile__header {
		display: block;
		width: 100%;
		height: 100vh;
		background: #2a454a;
		transition: ease-in-out all 0.5s;
		-webkit-transition: ease-in-out all 0.5s;
		-moz-transition: ease-in-out all 0.5s;
		-o-transition: ease-in-out all 0.5s;
		@media only screen and (max-width: 840px) and (orientation: landscape) {
			height: 220vh;
		}
	}

	.mobile__header-wrapper {
		width: 100%;
		height: 100vh;
		@media only screen and (max-width: 840px) and (orientation: landscape) {
			height: 220vh;
		}
	}
	// BEGIN BACKGROUND
	.mobile__header-sky-bg {
		width: 100%;
		height: 100vh;
		background: url("https://elasticbeanstalk-us-west-2-412710462953.s3-us-west-2.amazonaws.com/images/tablet-bg.jpg");
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		@media only screen and (max-width: 840px) and (orientation: landscape) {
			height: 200vh;
		}
	}

	// BEGIN MOBILE HERO
	.mobile__hero-heading {
		width: 100%;
		height: 100%;
		color: white;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	.mobile__hero-heading h1 {
		font-family: 'TIMMONS_NY_FINAL', serif;
		text-transform: uppercase;
		font-weight: normal;
		font-size: 9rem;
		line-height: 7rem;
		@media only screen and (max-width: 840px) and (orientation: landscape) {
			font-size: 6rem;
			line-height: 5rem;
			position: relative;
			bottom: 3.5rem;
		}
	}

	.mobile__hero-heading h1 span.lower__case {
		text-transform: lowercase;
	}

	.mobile__hero-heading h1 span.heading__stroke {
		-webkit-text-stroke: 2px white;
		letter-spacing: 5px;
		color: transparent;
	}

	.mobile__hero-heading-mobile h1 span.lower__case {
		text-transform: lowercase;
	}

	.mobile__hero-heading-mobile h1 span.heading__stroke {
		-webkit-text-stroke: 2px white;
		letter-spacing: 5px;
		color: transparent;
	}

	//END MOBILE HERO HEADING
	.mobile__header-mountain {
		width: 100%;
		height: 100vh;
		background: url("https://elasticbeanstalk-us-west-2-412710462953.s3-us-west-2.amazonaws.com/images/tablet-mountain.png");
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
		@media only screen and (max-width: 840px) and (orientation: landscape) {
			height: 200vh;
		}
	}

	//END BACKGROUND

	//MOBILE HERO HEADING P AND BUTTON
	.mobile__hero-heading p {
		width: 50%;
		font-family: "neue-haas-grotesk-text-pro", sans-serif;
		font-size: 1rem;
		color: white;
		margin-top: 5rem;
		margin-bottom: 1rem;
		position: relative;
		top: 50px;
		line-height: 1.35rem;
		@media only screen and (max-width: 840px) and (orientation: landscape) {
			margin-top: 3rem;
		}
	}

	.mobile__hero-heading a {
		font-family: "neue-haas-grotesk-text-pro", sans-serif;
		font-size: 0.75rem;
		color: white;
		text-decoration: none;
		text-transform: uppercase;
		border: 1px solid white;
		padding: 1rem;
		position: relative;
		top: 60px;
	}

	//NEXT CONTENT BLOCK
	.mobile__header-content {
		width: 100%;
		background: #2a454a;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 8rem;
		padding-bottom: 8rem;
	}

	.mobile__header-text {
		width: 80%;
	}

	.mobile__header-text h1 {
		width: 80%;
		font-family: "Poppins", sans-serif;
		color: white;
		font-weight: 600;
		font-size: 2rem;
	}

	.mobile__header-text h2 {
		width: 80%;
		font-family: "Poppins", sans-serif;
		color: #279989;
		padding-top: 3rem;
		font-weight: 600;
		font-size: 1.25rem;
	}
	.mobile__header-text a.drive__btn {
		font-family: "neue-haas-grotesk-text-pro", sans-serif;
		font-size: 0.75rem;
		font-weight: bold;
		color: white;
		text-decoration: none;
		text-transform: uppercase;
		border: 1px solid white;
		padding: 0.75rem;
		position: relative;
		top: 40px;
	}
}

//SHOW MOBILE BELOW 600px

@media only screen and (max-width: 599px) {
	#main__header-hero {
		display: none;
	}
	.business-tag {
		display: none;
	}

	.mobile__header {
		display: block;
		width: 100%;
		height: 100vh;
		background: #2a454a;
	}

	.mobile__header-wrapper {
		width: 100%;
		height: 100vh;
	}
	// BEGIN BACKGROUND
	.mobile__header-sky-bg {
		width: 100%;
		height: 100vh;
		background: url("https://elasticbeanstalk-us-west-2-412710462953.s3-us-west-2.amazonaws.com/images/mobile-bg.jpg");
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}

	// BEGIN MOBILE HERO
	.mobile__hero-heading {
		width: 100%;
		height: 100%;
		color: white;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	.mobile__hero-heading h1 {
		font-family: 'TIMMONS_NY_FINAL', serif;
		text-transform: uppercase;
		font-weight: normal;
		@media only screen and (min-width: 320px) {
			font-size: 30vw;
			line-height: 5.5rem;
		}
		@media only screen and (min-width: 392px) {
			line-height: 6.5rem;
		}
		@media only screen and (min-width: 466px) {
			line-height: 7.5rem;
		}
		@media only screen and (min-width: 544px) {
			line-height: 8.5rem;
		}
	}

	.mobile__hero-heading h1 span.lower__case {
		text-transform: lowercase;
	}

	.mobile__hero-heading h1 span.heading__stroke {
		-webkit-text-stroke: 2px white;
		letter-spacing: 5px;
		color: transparent;
	}

	.mobile__hero-heading-mobile h1 span.lower__case {
		text-transform: lowercase;
	}

	.mobile__hero-heading-mobile h1 span.heading__stroke {
		-webkit-text-stroke: 2px white;
		letter-spacing: 5px;
		color: transparent;
	}
	//END MOBILE HERO HEADING
	.mobile__header-mountain {
		width: 100%;
		height: 100vh;
		background: url("https://elasticbeanstalk-us-west-2-412710462953.s3-us-west-2.amazonaws.com/images/mobile-mountain.png");
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}
	//END BACKGROUND

	//MOBILE HERO HEADING P AND BUTTON
	.mobile__hero-heading p {
		font-family: "neue-haas-grotesk-text-pro", sans-serif;
		color: white;
		margin-top: 5rem;
		margin-bottom: 1rem;
		width: 65%;
		@media (min-width: 320px) and (max-width: 599px) {
			font-size: 1rem;
			position: relative;
			top: 50px;
			line-height: 1.35rem;
		}
	}

	.mobile__hero-heading a {
		font-family: "neue-haas-grotesk-text-pro", sans-serif;
		font-size: 2.5vw;
		color: white;
		text-decoration: none;
		text-transform: uppercase;
		border: 1px solid white;
		padding: 1rem;
		@media (min-width: 320px) and (max-width: 599px) {
			position: relative;
			top: 60px;
		}
	}

	//NEXT CONTENT BLOCK
	.mobile__header-content {
		width: 100%;
		background: #2a454a;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-bottom: 8rem;
		padding-top: 4rem;
	}

	.mobile__header-text {
		width: 80%;
	}

	.mobile__header-text h1 {
		width: 100%;
		font-family: "Poppins", sans-serif;
		color: white;
		font-weight: 600;
		font-size: 1.60rem;
	}
	.mobile__header-text h2 {
		width: 100%;
		font-family: "Poppins", sans-serif;
		color: #279989;
		padding-top: 3rem;
		font-weight: 600;
		font-size: 1rem;
	}
	.mobile__header-text a.drive__btn {
		font-family: "neue-haas-grotesk-text-pro", sans-serif;
		font-size: 0.75rem;
		font-weight: bold;
		color: white;
		text-decoration: none;
		text-transform: uppercase;
		border: 1px solid white;
		padding: 0.75rem;
		position: relative;
		top: 40px;
	}
}
