.main__nav-wrapper {
	width: 100vw;
	height: 5em;
	z-index: 21;
	position: fixed;
	padding-top: 1rem;
	padding-bottom: 1rem;
	right: 0;
}

.main__nav-container {
	width: 100vw;
	height: 5em;
	display: flex;
	justify-content: center;
	align-items: center;
}

.main__content-header {
	width: 90%;
	height: 5em;
	display: flex;
	align-items: center;
	justify-content: space-between;
	transition: 1s;
	-moz-transition: 1s;
	-webkit-transition: 1s;
	@media screen and (max-width: 1024px) {
		padding-left: 0.25rem;
		padding-right: 0.25rem;
		position: relative;
		left: 10px;
	}
}

.main__content-logo {
	transition: 1s;
	-moz-transition: 1s;
	-webkit-transition: 1s;
}

.main__content-logo svg {
	width: 3.75em;
	height: 3.75em;
	transition: 1s;
	-moz-transition: 1s;
	-webkit-transition: 1s;
	@media screen and (max-width: 414px) {
		width: 4em;
		height: 4em;
	}
}

.main__content-logo svg path {
	transition: 1s;
	-moz-transition: 1s;
	-webkit-transition: 1s;
}

.main__toggle-btn {
	cursor: pointer;
	transition: 1s;
	-moz-transition: 1s;
	-webkit-transition: 1s;
}

.main__toggle-btn span.one {
	display: block;
	width: 1.275em;
	height: 2px;
	background: #fff;
}

.main__toggle-btn span.two {
	display: block;
	width: 1.275em;
	height: 2px;
	margin-top: 0.500em;
	background: #fff;
}

// MENU

.main__menu-wrapper {
	z-index: 20;
	position: fixed;
	overflow: hidden;
	background: rgba(49, 78, 83, 0.90);
	@include full__sizing;
	right: -100%;
	bottom: 1px;
	display: none;
	@media only screen and (max-width: 840px) and (orientation: landscape) {
		top: 0;
		min-height: 100%;
		bottom: 0;
		overflow-y: hidden;
	}
}

.main__menu-logo svg {
	width: 3.750em;
	height: 3.750em;
}

.main__menu-logo {
	margin-top: 4em;
	font-family: "Poppins", sans-serif;
	font-size: 1rem;
}

.main__menu-logo a {
	text-decoration: none;
	color: white;
}

.main__menu-header {
	width: 80%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 auto;
	margin-bottom: 8em;
	@media screen and (max-width: 1024px) {
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 4.5em;
	}
}

// add content - grid with rest of nav/menu
.main__menu-subtext {
	width: 30%;
	display: flex;
	font-family: 'Poppins', sans-serif;
	margin-top: 3em;
	@media screen and (max-width: 1024px) {
		width: 100%;
	}
}

.main__menu-subtext p {
	font-size: 0.60em;
	font-weight: 300;
	color: white;
	@media screen and (max-width: 1024px) {
		margin-left: 0;
	}
}

// center menu items on screen *responsive
.main__menu-nav {
	width: 80%;
	margin: 0 auto;
	margin-top: 12em;
	margin-bottom: 8em;
	@media screen and (max-width: 1024px) {
		margin-top: 2em;
	}
	@media screen and (min-width: 2560px) {
		margin-top: 18em;
	}
	@media screen and (min-width: 3840px) {
		margin-top: 30em;
	}
}

.main__menu-nav li span.menu__item {
	font-size: 1rem;
	font-weight: 300;
	font-family: "Poppins", sans-serif;
	margin-right: 1rem;
	@media screen and (min-width: 320px) and (max-width: 489px) {
		display: none;
	}
}

.main__menu-nav ul {
	list-style: none;
}

.main__menu-nav ul li {
	color: #fff;
	line-height: 4.5em;
	@media screen and (max-width: 1024px) {
		line-height: 3em;
	}
}

.main__menu-nav ul li a {
	text-decoration: none;
	color: #fff;
	font-family: "Poppins", sans-serif;
	font-weight: 600;
	font-size: 5rem;
	line-height: 6rem;
	@media screen and (min-width: 662px) and (max-width: 1024px) {
		font-size: 3.5rem;
		line-height: 4.5rem;
	}
	@media screen and (min-width: 489px) and (max-width: 662px) {
		font-size: 2rem;
		line-height: 4.5rem;
	}
	@media screen and (min-width: 320px) and (max-width: 489px) {
		font-size: 3rem;
		line-height: 5rem;
	}
	@media screen and (max-width: 360px) {
		font-size: 1.25rem;
		line-height: 2.5rem;
	}
	@media only screen and (max-width: 840px) and (orientation: landscape) {
		line-height: 2.25rem;
	}
}

.main__menu-nav ul li a:hover {
	-webkit-text-stroke: 1px white;
	color: transparent;
	transition: 0.3s;
}

.main__menu-social {
	width: 80%;
	margin: 0 auto;
	display: flex;
	align-items: center;
	@media screen and (max-width: 1024px) {
		flex-direction: column;
		align-items: flex-start;
	}
	@media screen and (min-width: 2560px) {
		margin-top: 5em;
	}
	@media screen and (min-width: 3840px) {
		margin-top: 10em;
	}
}

.main__menu-social-links {
	display: flex;
	align-items: center;
}

.main__menu-social-links a svg {
	width: 1.25em;
	height: 1.25em;
	@media screen and (max-width: 1024px) {
		width: 1em;
		height: 1em;
	}
	@media screen and (min-width: 2560px) {
		width: 1.5em;
		height: 1.5em;
	}
	@media screen and (min-width: 3840px) {
		width: 2em;
		height: 2em;
	}
}

.main__menu-social-links a svg path,
.main__menu-social-links a svg circle {
	fill: white !important;
}

.main__menu-social-links a:hover svg path {
	fill: #3ea89a !important;
	transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
	-ms-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
}

.main__menu-social-links a {
	padding-right: 2em;
	margin-bottom: 1em;
	@media screen and (max-width: 1024px) {
		margin-right: 0.25em;
	}
}

.main__test {
	@media screen and (max-width: 1024px) {
		width: 100%;
		height: 80vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}

.main__menu-contact {
	font-family: 'Poppins', sans-serif;
	font-size: 1rem;
	font-weight: 600;
	margin-bottom: 1em;
	margin-left: 5em;
	@media screen and (max-width: 1024px) {
		margin-left: 0;
		display: none;
	}
}

.mobile__contact-email {
	display: none;
	@media screen and (max-width: 1024px) {
		display: flex;
		color: white;
		font-family: 'Poppins', sans-serif;
		font-size: 0.5rem;
		font-weight: 900;
		margin-bottom: 2em;
		justify-content: flex-start;
		width: 100%;
	}
}

.mobile__contact-email a {
	@media screen and (max-width: 1024px) {
		color: white;
		font-family: 'Poppins', sans-serif;
		font-size: 0.5rem;
		font-weight: 600;
		margin-bottom: 2em;
		text-decoration: none;
	}
	@media screen and (min-width: 662px) and (max-width: 1024px) {
		font-size: 1rem;
	}
	@media screen and (min-width: 468px) and (max-width: 662px) {
		font-size: 0.75rem;
	}
	@media screen and (min-width: 320px) and (max-width: 468px) {
		font-size: 0.60rem;
		width: 100%;
	}
}

.mobile__contact-email a.mobile__email {
	@media screen and (max-width: 1024px) {
		color: white;
		font-family: 'Poppins', sans-serif;
		font-size: 0.5rem;
		font-weight: 600;
		margin-bottom: 2em;
		text-decoration: none;
		margin-right: 2em;
	}
	@media screen and (min-width: 662px) and (max-width: 1024px) {
		font-size: 1rem;
	}
	@media screen and (min-width: 468px) and (max-width: 662px) {
		font-size: 0.75rem;
	}
	@media screen and (min-width: 320px) and (max-width: 468px) {
		font-size: 0.60rem;
		width: 100%;
	}
}

.main__menu-contact a.desktop__email {
	color: white;
	text-decoration: none;
	font-size: 1em;
	font-family: "Poppins", sans-serif;
	color: white;
	position: relative;
	font-weight: 500;
	letter-spacing: 0.10em;
	text-decoration: none;
	cursor: pointer;
	@media screen and (min-width: 2560px) {
		font-size: 1.5em;
	}
	@media screen and (min-width: 3840px) {
		font-size: 2em;
	}
}
// BUSINESS WITH ALT STARTS HERE
.business-tag h3 {
	font-family: "Poppins", sans-serif;
	font-size: 0.75rem;
	color: white;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 400;
}
//BUSINESS TAG ENDS HERE
