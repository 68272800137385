.primary__title {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.primary__title img {
	margin-bottom: 2.5em;
	width: 5%;
	@media screen and (max-width: 1024px) {
		width: 6%;
	}
	@media screen and (max-width: 768px) {
		width: 9%;
	}
	@media screen and (max-width: 425px) {
		width: 15%;
	}
	@media screen and (max-width: 375px) {
		width: 20%;
	}
	@media screen and (max-width: 320px) {
		width: 25%;
	}
}

.primary__title h1 {
	font-family: "Poppins", sans-serif;
	font-size: 4em;
	color: white;
	text-align: center;
	line-height: 1.25em;
	@media screen and (max-width: 768px) {
		font-size: 3em;
	}
	@media screen and (max-width: 425px) {
		font-size: 2em;
	}
	@media screen and (max-width: 320px) {
		font-size: 3em;
	}
}

.primary__title p {
	font-family: "Poppins", sans-serif;
	font-size: 1em;
	color: white;
	text-align: center;
	font-weight: 300;
	margin-top: 2em;
	margin-left: 30%;
	margin-right: 30%;
	line-height: 2.25em;
	@media screen and (max-width: 1024px) {
		margin-left: 20%;
		margin-right: 20%;
	}
	@media screen and (max-width: 768px) {
		margin-left: 10%;
		margin-right: 10%;
	}
	@media screen and (max-width: 425px) {
		margin-left: 5%;
		margin-right: 5%;
	}
	@media screen and (max-width: 320px) {
		margin-left: 0;
		margin-right: 0;
	}
}

// DESKTOP ARTICLE SECTION START

.main__article-section-desktop {
	display: none;
}

@media screen and (min-width: 1024px) {
	.main__article-section-desktop {
		width: 100%;
		height: 100%;
		padding-top: 8rem;
		padding-bottom: 8rem;
		background: #e6f2ee;
		display: flex;
		justify-content: center;
	}

	.main__article-section-wrapper-desktop {
		width: 80%;
		height: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		transition: ease-in-out all 0.5s;
		-webkit-transition: ease-in-out all 0.5s;
		-moz-transition: ease-in-out all 0.5s;
		-o-transition: ease-in-out all 0.5s;
	}

	.main__article-section-primary {
		width: 50%;
		height: 100%;
	}

	.main__article-field-tag-desktop {
		width: 100%;
		margin-bottom: 2rem;
		margin-top: 4rem;
	}

	.main__article-field-tag-desktop h4 {
		color: #939598;
		text-transform: uppercase;
		font-family: 'Poppins', sans-serif;
		font-weight: 300;
		letter-spacing: 0.05rem;
		font-size: 0.75rem;
	}

	.main__article-field-tag-desktop h4 span.title__color {
		color: black;
	}

	.main__article-hero-desktop img {
		width: 80%;
		height: 40vh;
		border-bottom: 10px solid #279989;
		object-fit: cover;
		object-position: center;
	}

	.main__article-title-desktop {
		width: 70%;
		padding-top: 4rem;
	}

	.main__article-title-desktop h1 {
		font-family: "Poppins", sans-serif;
		font-weight: 500;
		color: black;
		font-size: 3.25rem;
		line-height: 3.75rem;
	}

	.main__article-post-subtitle-desktop {
		width: 80%;
		padding-top: 2rem;
	}

	.main__article-post-subtitle-desktop h2 {
		font-family: "Neue Haas Grotesk Text Pro", sans-serif;
		color: #2a4549;
		font-size: 2rem;
		line-height: 2.5rem;
		font-weight: 300;
	}

	.main__article-desktop {
		width: 80%;
		padding-top: 2rem;
	}

	.main__article-desktop p {
		font-family: "Neue Haas Grotesk Text Pro", sans-serif;
		font-size: 1.35rem;
		line-height: 2rem;
		color: #2a4549;
	}

	// SECONDARY ARTICLE START

	.main__article-section-secondary {
		width: 50%;
		height: 100%;
	}

	.main__sub-article {
		border-left: 2px solid #279989;
	}

	.main__sub-article-border {
		padding-left: 5rem;
	}

	.main__sub-article-field-tag-desktop {
		width: 100%;
		margin-bottom: 2rem;
		margin-top: 4rem;
	}

	.main__sub-article-field-tag-desktop h4 {
		color: #939598;
		text-transform: uppercase;
		font-family: 'Poppins', sans-serif;
		font-weight: 300;
		letter-spacing: 0.05rem;
		font-size: 0.75rem;
	}

	.main__sub-article-field-tag-desktop h4 span.title__color {
		color: black;
	}

	.main__sub-article-hero-desktop img {
		width: 80%;
		height: 20vh;
		border-bottom: 10px solid #279989;
		object-fit: cover;
		object-position: center;
	}

	.main__sub-article-title-desktop {
		width: 70%;
		padding-top: 3rem;
	}

	.main__sub-article-title-desktop h1 {
		font-family: "Poppins", sans-serif;
		font-weight: 500;
		color: black;
		font-size: 2rem;
		line-height: 2.75rem;
	}

	.main__sub-article-post-subtitle-desktop {
		width: 80%;
		padding-top: 1rem;
	}

	.main__sub-article-post-subtitle-desktop h2 {
		font-family: "Neue Haas Grotesk Text Pro", sans-serif;
		color: #2a4549;
		font-size: 1.25rem;
		line-height: 2rem;
		font-weight: 300;
	}

	.main__sub-article-button-desktop {
		padding-top: 2.5rem;
	}

	.main__sub-article-button-desktop a.sub-article-button {
		font-family: "Poppins", sans-serif;
		font-size: 1rem;
		font-weight: 500;
		color: #279989;
		text-decoration: none;
	}

	.main__sub-article-wrapper-two-desktop {
		margin-top: 10rem;
	}
}

// MOBILE ARTICLE SECTION START

.main__article-section-mobile {
	display: none;
}

@media screen and (max-width: 1023px) {
	.main__article-section-mobile {
		display: block;
		width: 100%;
		min-height: 100%;
		background: #e6f2ee;
		padding-top: 2rem;
		padding-bottom: 2rem;
		transition: ease-in-out all 0.5s;
		-webkit-transition: ease-in-out all 0.5s;
		-moz-transition: ease-in-out all 0.5s;
		-o-transition: ease-in-out all 0.5s;
	}

	.main__article-mobile {
		width: 100%;
		height: 100%;
	}

	.main__article-wrapper-mobile {
		width: 100%;
		height: 100%;
	}

	.main__article-hero-mobile {
		width: 100%;
		height: 25vh;
	}

	.main__article-hero-mobile img {
		width: 100%;
		height: 25vh;
		border-bottom: 10px solid #279989;
		object-fit: cover;
		object-position: center;
	}

	.main__article-content-wrapper-mobile {
		width: 80%;
		height: 100%;
		padding-bottom: 2rem;
		margin: 0 auto;
	}

	.main__article-field-tag-mobile {
		width: 100%;
		margin-bottom: 2rem;
		display: flex;
		align-items: center;
		margin-top: 4rem;
	}

	.main__article-field-tag-mobile h4 span.title__color {
		color: black;
	}

	.main__article-post-subtitle-mobile {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.main__article-mobile h1 {
		font-family: "Poppins", sans-serif;
		font-weight: 500;
		color: black;
		font-size: 2rem;
		line-height: 2.75rem;
		padding-top: 1rem;
	}

	.main__article-mobile h2 {
		font-family: "Neue Haas Grotesk Text Pro", sans-serif;
		color: #2a4549;
		font-size: 1.25rem;
		line-height: 2rem;
		font-weight: 300;
	}

	.main__article-mobile h4 {
		color: #939598;
		text-transform: uppercase;
		font-family: 'Poppins', sans-serif;
		font-weight: 300;
		letter-spacing: 0.05rem;
		font-size: 0.75rem;
	}

	.main__article-mobile h4.date {
		color: black;
	}

	.main__article-mobile h3 {
		color: black;
		font-family: 'Poppins', sans-serif;
		font-weight: 500;
		letter-spacing: 0.05rem;
		font-size: 1rem;
	}

	.main__article-post-contributors-mobile {
		padding-bottom: 2rem;
		display: flex;
		align-items: center;
	}

	.main__article-post-contributors-mobile h4.contributors-mobile {
		padding-left: 1rem;
		color: black;
	}

	.main__article-mobile p {
		font-family: "Neue Haas Grotesk Text Pro", sans-serif;
		font-size: 1rem;
		line-height: 1.75rem;
		color: #2a4549;
	}

	.main__article-button {
		padding-top: 2rem;
	}

	.main__article-button a.article-button {
		font-family: "neue-haas-grotesk-text-pro", sans-serif;
		font-size: 0.75rem;
		font-weight: bold;
		color: #2a4549;
		text-decoration: none;
		text-transform: uppercase;
		border: 1px solid #2a4549;
		padding: 0.75rem;
	}
}

// MAIN SUB ARTICLES
.main__sub-article-section-mobile {
	display: none;
}

@media screen and (max-width: 1023px) {
	.main__sub-article-section-mobile {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		background: #e6f2ee;
		padding-bottom: 5rem;
		transition: ease-in-out all 0.5s;
		-webkit-transition: ease-in-out all 0.5s;
		-moz-transition: ease-in-out all 0.5s;
		-o-transition: ease-in-out all 0.5s;
	}

	.arrows {
		width: 100%;
		display: flex;
		align-self: flex-start;
		padding-top: 3rem;
		padding-left: 3rem;
		padding-right: 3rem;
		justify-content: space-between;
	}

	.arrows button {
		background: #e6f2ee;
	}

	.arrows #slideLeft, .arrows #slideRight {
		font-size: 2rem;
		color: #279989;
	}

	.arrows button svg {
		width: 30px;
	}

	#main__sub-article-wrap {
		width: 75%;
		height: 100%;
		display: flex;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		scroll-snap-type: x mandatory;
		background: #e6f2ee;
	}

	.main__sub-article-wrapper-one {
		flex-shrink: 0;
		width: 300px;
		height: 100%;
		padding-right: 2rem;
		scroll-snap-type: x mandatory;
	}

	.main__sub-article-wrapper-one a {
		text-decoration: none;
	}

	.main__sub-article-wrapper-two {
		flex-shrink: 0;
		width: 300px;
		height: 100%;
		scroll-snap-type: x mandatory;
	}

	.main__sub-article-wrapper-two a {
		text-decoration: none;
	}

	.main__sub-article-wrapper-one h4 {
		color: #939598;
		text-transform: uppercase;
		font-family: 'Poppins', sans-serif;
		font-weight: 300;
		letter-spacing: 0.05rem;
		font-size: 0.75rem;
	}

	.main__sub-article-wrapper-two h4 {
		color: #939598;
		text-transform: uppercase;
		font-family: 'Poppins', sans-serif;
		font-weight: 300;
		letter-spacing: 0.05rem;
		font-size: 0.75rem;
	}

	.main__sub-article-field-tag {
		width: 100%;
		margin-bottom: 2rem;
		display: flex;
		align-items: center;
	}

	.main__sub-article-field-tag h4 span.title__color {
		color: black;
	}

	.main__sub-article-hero {
		width: 100%;
		height: 10vh;
	}

	.main__sub-article-hero img {
		width: 100%;
		height: 10vh;
		border-bottom: 10px solid #279989;
		object-fit: cover;
		object-position: center;
	}

	.main__sub-article-title h1 {
		font-family: "Poppins", sans-serif;
		font-weight: 500;
		color: black;
		font-size: 1.25rem;
		line-height: 2rem;
		padding-top: 1rem;
		padding-bottom: 1.5rem;
	}

	.main__sub-article p {
		font-family: "Neue Haas Grotesk Text Pro", sans-serif;
		font-size: 1rem;
		line-height: 1.75rem;
		color: #2a4549;
	}
}

//MOBILE ARTICLE END

// EXPEDITION CTA
.main__exp-cta {
	width: 100%;
	display: flex;
	justify-content: center;
	background: white;
	padding-top: 5rem;
	padding-bottom: 5rem;
}

.main__exp-wrapper {
	width: 80%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
	@media screen and (max-width: 1024px) {
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	}
}

.main__exp-badge {
	width: 80%;
	height: 100%;
	@media screen and (max-width: 1024px) {
		width: 60%;
	}
	@media screen and (max-width: 767px) {
		width: 80%;
		margin-right: auto;
	}
	@media screen and (max-width: 450px) {
		width: 100%;
		margin-right: auto;
	}
}

.main__exp-badge img {
	width: 75%;
	@media screen and (max-width: 1024px) {
		margin: 0 auto;
		width: 100%;
	}
}

.main__exp-action {
	width: 80%;
	height: 100%;
	@media screen and (max-width: 1024px) {
		padding-top: 2rem;
		width: 100%;
	}
}

// DESKTOP TAG
.main__exp-tag {
	width: 100%;
	margin-bottom: 1.5rem;
	display: flex;
	align-items: center;
	@media screen and (max-width: 1024px) {
		display: none;
	}
}

.main__exp-tag h4 {
	color: #2a4549;
	text-transform: uppercase;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	letter-spacing: 0.05rem;
	font-size: 0.75rem;
	padding-left: 2rem;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 0.8rem;
	}
	@media only screen and (min-width: 620px) {
		font-size: 1.5vw;
	}
	@media only screen and (min-width: 768px) {
		font-size: 1.25vw;
	}
	@media only screen and (min-width: 920px) {
		font-size: 1vw;
	}
	@media only screen and (min-width: 1278px) {
		font-size: 0.7vw;
	}
	@media only screen and (min-width: 1920px) {
		font-size: 0.6vw;
	}
}

.main__exp-tag span {
	height: 0.1rem;
	background: #2a4549;
	width: 3rem;
}

// MOBILE TAG
.main__exp-tag-two {
	display: none;
}

@media screen and (max-width: 1024px) {
	.main__exp-tag-two {
		display: block;
		width: 100%;
		margin-bottom: 1.5rem;
		display: flex;
		align-items: center;
	}

	.main__exp-tag-two h4 {
		color: #2a4549;
		text-transform: uppercase;
		font-family: 'Poppins', sans-serif;
		font-weight: 600;
		letter-spacing: 0.05rem;
		font-size: 0.75rem;
		padding-left: 2rem;
		@media (min-width: 320px) and (max-width: 599px) {
			font-size: 0.8rem;
		}
		@media only screen and (min-width: 620px) {
			font-size: 1.5vw;
		}
		@media only screen and (min-width: 768px) {
			font-size: 1.25vw;
		}
		@media only screen and (min-width: 920px) {
			font-size: 1vw;
		}
		@media only screen and (min-width: 1278px) {
			font-size: 0.7vw;
		}
		@media only screen and (min-width: 1920px) {
			font-size: 0.6vw;
		}
	}

	.main__exp-tag-two span {
		height: 0.1rem;
		background: #2a4549;
		width: 3rem;
	}
}

.main__exp-info-content {
	width: 100%;
}

.main__exp-info-content h1 {
	font-family: "Poppins", sans-serif;
	color: #2a4549;
	font-size: 3rem;
	font-weight: 500;
	width: 100%;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 2rem;
		width: 100%;
	}
	@media (min-width: 599px) and (max-width: 1024px) {
		font-size: 2rem;
		width: 100%
	}
	@media only screen and (min-width: 1024px) {
		font-size: 3rem;
		width: 100%;
	}
	@media only screen and (min-width: 1278px) {
		font-size: 2.75vw;
		width: 100%;
	}
	@media only screen and (min-width: 1400px) {
		font-size: 2.25vw;
		width: 100%;
	}
	@media only screen and (min-width: 1920px) {
		font-size: 3rem;
		line-height: 3.75rem;
		width: 100%;
	}
}

.main__exp-article {
	padding-top: 2rem;
	width: 100%;
}

.main__exp-article p {
	width: 80%;
	font-family: "Neue Haas Grotesk Text Pro", sans-serif;
	font-size: 1.35rem;
	line-height: 2rem;
	color: #2a4549;
	@media screen and (max-width: 1024px) {
		width: 100%;
	}
	@media screen and (max-width: 599px) {
		width: 100%;
	}
}

.main__exp-article-button-desktop {
	padding-top: 2.5rem;
}

.main__exp-article-button-desktop a.exp-article-button {
	font-family: "Poppins", sans-serif;
	font-size: 1rem;
	font-weight: 500;
	color: #279989;
	text-decoration: none;
}

// CTA FIRST CONTAINER
.home__approach-cta-one {
	width: 100%;
	min-height: 45vh;
	padding-bottom: 3rem;
	background: #2a454a;
	@media (min-width: 320px) and (max-width: 599px) {
		min-height: 40vh;
		padding-bottom: 0;
	}
}

.home__approach-hero-one {
	width: 100%;
	height: 100%;
	background: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
}

.home__approach-wrap-one {
	width: 80%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	margin-top: 10rem;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
	@media only screen and (max-width: 840px) and (orientation: landscape) {
		margin-top: 4rem;
	}
	@media (min-width: 320px) and (max-width: 599px) {
		margin-top: 6rem;
	}
}

.home__approach-tag-one {
	width: 100%;
	margin-bottom: 1.5rem;
	display: flex;
	align-items: center;
}

.home__approach-tag-one h4 {
	color: white;
	text-transform: uppercase;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	letter-spacing: 0.05rem;
	font-size: 0.75rem;
	padding-left: 2rem;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 0.8rem;
	}
	@media only screen and (min-width: 620px) {
		font-size: 1.5vw;
	}
	@media only screen and (min-width: 768px) {
		font-size: 1.25vw;
	}
	@media only screen and (min-width: 920px) {
		font-size: 1vw;
	}
	@media only screen and (min-width: 1278px) {
		font-size: 0.7vw;
	}
	@media only screen and (min-width: 1920px) {
		font-size: 0.6vw;
	}
}

.home__approach-tag-one span {
	height: 0.1rem;
	background: white;
	width: 3rem;
}

.home__approach-container-one {
	width: 100%;
}

.home__approach-container-one h1 {
	font-family: "Poppins", sans-serif;
	color: white;
	font-size: 2.5rem;
	font-weight: 500;
	width: 80%;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 1.25rem;
		width: 100%;
	}
	@media (min-width: 599px) and (max-width: 1023px) {
		font-size: 1.25rem;
		width: 60%;
	}
	@media only screen and (min-width: 1024px) {
		font-size: 3.15vw;
		width: 70%;
	}
	@media only screen and (min-width: 1278px) {
		font-size: 2.75vw;
		width: 80%;
	}
	@media only screen and (min-width: 1400px) {
		font-size: 2.25vw;
		width: 100%;
	}
	@media only screen and (min-width: 1920px) {
		font-size: 2vw;
		width: 100%;
	}
}

.home__approach-container-one h1 br {
	@media only screen and (max-width: 1320px) {
		display: none;
	}
}

// SECOND SECTION
.home__approach-cta-two {
	width: 100%;
	height: 60vh;
	background: #e6f2ee;
	@media only screen and (max-width: 840px) and (orientation: landscape) {
		height: 100vh;
	}
}

.home__approach-hero-two {
	width: 100%;
	height: 100%;
	background: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
}

.home__approach-wrap-two {
	width: 80%;
	height: 60vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

.home__approach-tag-two {
	width: 100%;
	margin-bottom: 3rem;
	display: flex;
	align-items: center;
}

.home__approach-tag-two h4 {
	color: #284346;
	text-transform: uppercase;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	letter-spacing: 0.05rem;
	font-size: 1rem;
	padding-left: 2rem;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 0.8rem;
	}
	@media only screen and (min-width: 620px) {
		font-size: 1.5vw;
	}
	@media only screen and (min-width: 768px) {
		font-size: 1.25vw;
	}
	@media only screen and (min-width: 920px) {
		font-size: 1vw;
	}
	@media only screen and (min-width: 1278px) {
		font-size: 0.7vw;
	}
	@media only screen and (min-width: 1920px) {
		font-size: 0.6vw;
	}
}

.home__approach-tag-two span {
	height: 0.2rem;
	background: #284346;
	width: 3rem;
}

.home__approach-container-two {
	width: 100%;
}

.home__approach-container-two h1 {
	width: 100%;
	font-family: "Poppins", sans-serif;
	color: #284346;
	font-size: 3rem;
	font-weight: 600;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 1.55rem;
		margin-bottom: 1rem;
	}
	@media (min-width: 599px) and (max-width: 1024px) {
		font-size: 2rem;
		margin-bottom: 1rem;
	}
	@media only screen and (min-width: 1025px) {
		font-size: 3.35vw;
		width: 70%;
	}
	@media only screen and (min-width: 1278px) {
		font-size: 3vw;
		width: 80%;
	}
	@media only screen and (min-width: 1400px) {
		font-size: 2.50vw;
		width: 100%;
	}
	@media only screen and (min-width: 1920px) {
		font-size: 2.25vw;
		width: 100%;
	}
}

.home__approach-container-two h1 br {
	@media only screen and (max-width: 1320px) {
		display: none;
	}
}

//unassigned section
.una__section {
	width: 100%;
	min-height: 50vh;
	padding-bottom: 5rem;
	background: #2a454a;
	display: flex;
	justify-content: center;

}

.una-wrapper {
	width: 80%;
	display: flex;
	justify-content: flex-start;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
	@media screen and (max-width: 1024px) {
		flex-direction: column;
	}
}


.una__one {
	width: 27%;
	display: flex;
	flex-direction: column;
	padding-right: 1rem;
	@media screen and (max-width: 1024px) {
		width: 100%;
		height: 250px;
		border-left: none;
		padding-left: 0;
	}
}

.una__two {
	width: 27%;
	display: flex;
	flex-direction: column;
	border-left: 2px solid rgba(211, 211, 211, 0.075);
	padding-left: 4rem;
	margin-left: 6rem;
	@media screen and (max-width: 1024px) {
		width: 100%;
		height: 250px;
		border-left: none;
		padding-left: 0;
		margin-left: 0;
		padding-top: 2rem;
		border-top: 2px solid rgba(211, 211, 211, 0.075);
	}
}

.una__three {
	width: 27%;
	display: flex;
	flex-direction: column;
	border-left: 2px solid rgba(211, 211, 211, 0.075);
	padding-left: 4rem;
	margin-left: 6rem;
	@media screen and (max-width: 1024px) {
		width: 100%;
		height: 250px;
		border-left: none;
		padding-left: 0;
		margin-left: 0;
		margin-top: 2rem;
		padding-top: 2rem;
		border-top: 2px solid rgba(211, 211, 211, 0.075);
	}
}

.una__one h4, .una__two h4, .una__three h4 {
	font-family: "Poppins", sans-serif;
	font-size: 16px;
	color: white;
	margin-left: 1rem;
	font-weight: 300;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 0.75rem;
	}
}

.una__one h1, .una__two h1, .una__three h1 {
	font-family: "Poppins", sans-serif;
	font-size: 2rem;
	color: white;
	font-weight: 500;
	@media (min-width: 599px) and (max-width: 1024px) {
		font-size: 1.05rem;
		margin-top: 1rem;
	}
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 1.15rem;
		margin-top: 1rem;
	}
}

.una__one p, .una__two p, .una__three p {
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
	font-size: 1.25rem;
	line-height: 1.5rem;
	color: white;
	font-weight: 400;
	@media (min-width: 320px) and (max-width: 599px) {
		line-height: 1.5rem;
		font-size: 1rem;
	}
	@media (min-width: 599px) and (max-width: 1024px) {
		width: 75%;
		font-size: 1rem;
	}
}

.una__one span, .una__two span, .una__three span {
	color: white;
}

.panel__line-text h4.temp-color {
	font-family: "Poppins", sans-serif;
	font-size: 16px;
	color: #279989;
	margin-left: 1rem;
	font-weight: 300;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 0.75rem;
	}
}



// container panel test start
.panel__full-wrapper {
	width: 100%;
	// HIDDEN FOR NOW - REMOVE TO BRING SECTION BACK
	display: none;
	@media screen and (max-width: 1024px) {
		display: none;
	}
}

.panel-test-background {
	width: 100%;
	height: 790px;
	position: absolute;
	display: flex;
}

.panel-test-one {
	flex-grow: 1;
	height: 790px;
	opacity: 0.3;
	background: url("https://elasticbeanstalk-us-west-2-412710462953.s3-us-west-2.amazonaws.com/images/photo-1-1.jpg");
	background-size: contain contain;
	background-repeat: no-repeat;
	background-position: center center;
	width: 0%;
	transition: 0.5s;
	transition-delay: 0.2s;
}

.panel-test-one:hover {
	width: 100%;
	opacity: 0.3;
	background: url("https://elasticbeanstalk-us-west-2-412710462953.s3-us-west-2.amazonaws.com/images/photo-1-1.jpg");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain contain;
}

.panel-test-two {
	flex-grow: 1;
	height: 790px;
	opacity: 0.3;
	background: url("https://elasticbeanstalk-us-west-2-412710462953.s3-us-west-2.amazonaws.com/images/photo-2-2.jpg");
	background-size: cover cover;
	background-repeat: no-repeat;
	background-position: center center;
	width: 0%;
	transition: 0.5s;
	transition-delay: 0.2s;
}

.panel-test-two:hover {
	width: 100%;
	opacity: 0.3;
	background: url("https://elasticbeanstalk-us-west-2-412710462953.s3-us-west-2.amazonaws.com/images/photo-2-2.jpg");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover cover;
}

.panel-test-three {
	flex-grow: 1;
	height: 790px;
	opacity: 0.3;
	background: url("https://elasticbeanstalk-us-west-2-412710462953.s3-us-west-2.amazonaws.com/images/photo-3-3.jpg");
	background-position: center center;
	background-repeat: no-repeat;
	width: 0%;
	transition: 0.5s;
	transition-delay: 0.2s;
}

.panel-test-three:hover {
	width: 100%;
	opacity: 0.3;
	background: url("https://elasticbeanstalk-us-west-2-412710462953.s3-us-west-2.amazonaws.com/images/photo-3-3.jpg");
	background-position: center center;
	background-repeat: no-repeat;
}

.panel-container {
	width: 100%;
	height: 790px;
	display: flex;
	background: #2a454a;
	@media screen and (max-width: 768px) {
		display: flex;
		flex-direction: column;
	}
}

.panel-one {
	flex-grow: 1;
	height: 790px;
	position: relative;
	pointer-events: none;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

.border-line {
	bottom: 75vh;
	right: 0;
	width: .1rem;
	height: 0;
	background-color: #fff;
	opacity: .1;
	transition: 0.1s;
	transition-delay: 0.2s;
}

.height-class {
	height: 100%;
}

.opacity {
	opacity: 0.5;
}

.panel-two {
	flex-grow: 1;
	height: 790px;
	position: relative;
	pointer-events: none;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

.panel-three {
	flex-grow: 1;
	width: 50%;
	height: 790px;
	position: relative;
	pointer-events: none;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
}

.panel-text-one {
	flex-grow: 1;
	height: 790px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 3rem;
	pointer-events: none;
	position: relative;
	left: 120px;
}

.panel-text-two {
	flex-grow: 1;
	height: 790px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 3rem;
	pointer-events: none;
	position: relative;
	left: 50px;
}

.panel-text-three {
	flex-grow: 1;
	height: 790px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 3rem;
	pointer-events: none;
	position: relative;
	left: 50px;
}

.panel__line-text {
	display: flex;
	align-items: center;
}

span.panel__line {
	width: 25px;
	height: 2px;
	background: #3ea89a;
}

.panel__line-text h4 {
	font-family: "Poppins", sans-serif;
	font-size: 16px;
	color: white;
	margin-left: 1rem;
	font-weight: 300;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 0.75rem;
	}
}

.panel-text-one h1 {
	font-family: "Poppins", sans-serif;
	font-size: 2rem;
	color: white;
	font-weight: 500;
}

.panel-text-one p {
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
	font-size: 1.25rem;
	line-height: 1.5rem;
	color: white;
	font-weight: 400;
	opacity: 0;
}

.panel-text-one span {
	color: white;
}

.panel-text-two h1 {
	font-family: "Poppins", sans-serif;
	font-size: 2rem;
	color: white;
	font-weight: 500;
}

.panel-text-two p {
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
	font-size: 1.25rem;
	line-height: 1.5rem;
	color: white;
	font-weight: 400;
	opacity: 0;
}

.panel-text-two span {
	color: white;
}

.panel-text-three h1 {
	font-family: "Poppins", sans-serif;
	font-size: 2rem;
	color: white;
	font-weight: 500;
}

.panel-text-three p {
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
	font-size: 1.25rem;
	line-height: 1.5rem;
	color: white;
	font-weight: 400;
	opacity: 0;
}

.panel-text-one .text-top {
	width: 75%;
	height: 100%;
}

.panel-text-two .text-top {
	width: 75%;
	height: 100%;
}

.panel-text-three .text-top {
	width: 75%;
	height: 100%;
}

.panel-text-three span {
	color: white;
}

.panel-text-one .text-top img {
	width: 10%;
	margin-bottom: 0.75rem;
}

.panel-text-two .text-top img {
	width: 18%;
}

.panel-text-three .text-top img {
	width: 16%;
	margin-bottom: 1.5rem;
}

.panel-text-one .text-top p br {
	@media screen and (max-width: 1640px) {
		display: none;
	}
}

.panel-text-two .text-top p br {
	@media screen and (max-width: 1640px) {
		display: none;
	}
}

.panel-text-three .text-top p br {
	@media screen and (max-width: 1640px) {
		display: none;
	}
}

// PANEL MOBILE
.panel__mobile {
	display: none;
}

@media screen and (max-width: 1024px) {
	.panel__mobile {
		width: 100%;
		background: white;
		// HIDDEN FOR NOW - REMOVE TO BRING SECTION BACK
		display: none;
	}

	.panel__mobile-container {
		width: 100%;
	}

	.panel__mobile-1 {
		width: 100%;
		height: 400px;
		background: url("https://elasticbeanstalk-us-west-2-412710462953.s3-us-west-2.amazonaws.com/images/photo-1-1.jpg");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: ease-in-out all 0.5s;
		-webkit-transition: ease-in-out all 0.5s;
		-moz-transition: ease-in-out all 0.5s;
		-o-transition: ease-in-out all 0.5s;
		@media (min-width: 320px) and (max-width: 599px) {
			padding: 0;
		}
		@media (min-width: 599px) and (max-width: 1024px) {
			padding: 0;
		}
	}

	.panel__mobile-2 {
		width: 100%;
		height: 400px;
		background: url("https://elasticbeanstalk-us-west-2-412710462953.s3-us-west-2.amazonaws.com/images/photo-2-2.jpg");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: ease-in-out all 0.5s;
		-webkit-transition: ease-in-out all 0.5s;
		-moz-transition: ease-in-out all 0.5s;
		-o-transition: ease-in-out all 0.5s;
		@media (min-width: 320px) and (max-width: 599px) {
			padding: 0;
		}
		@media (min-width: 599px) and (max-width: 1024px) {
			padding: 0;
		}
	}

	.panel__mobile-3 {
		width: 100%;
		height: 400px;
		background: url("https://elasticbeanstalk-us-west-2-412710462953.s3-us-west-2.amazonaws.com/images/photo-3-3.jpg");
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: ease-in-out all 0.5s;
		-webkit-transition: ease-in-out all 0.5s;
		-moz-transition: ease-in-out all 0.5s;
		-o-transition: ease-in-out all 0.5s;
		@media (min-width: 320px) and (max-width: 599px) {
			padding: 0;
		}
		@media (min-width: 599px) and (max-width: 1024px) {
			padding: 0;
		}
	}

	.panel__mobile-content {
		width: 80%;
		padding: 5rem;
		@media (min-width: 320px) and (max-width: 599px) {
			height: 350px;
			padding: 0rem;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			align-items: flex-start;
		}
		@media (min-width: 599px) and (max-width: 1024px) {
			height: 350px;
			padding: 0rem;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			align-items: flex-start;
		}
	}

	.panel__mobile-content h1 {
		color: white;
		font-family: "Poppins", sans-serif;
		font-weight: 500;
		@media (min-width: 320px) and (max-width: 599px) {
			font-size: 1.15rem;
			margin-top: 1rem;
		}
	}

	.panel__mobile-content p {
		font-size: 1.25rem;
		color: white;
		font-family: "neue-haas-grotesk-text-pro", sans-serif;
		@media (min-width: 320px) and (max-width: 599px) {
			line-height: 1.5rem;
			font-size: 1rem;
		}
		@media (min-width: 599px) and (max-width: 1024px) {
			width: 75%;
			font-size: 1rem;
		}
	}
}

// MAIN STRAT SECTION

.main__strat-section {
	width: 100%;
	height: 100vh;
	background: url("https://elasticbeanstalk-us-west-2-412710462953.s3-us-west-2.amazonaws.com/images/website-mountain.jpg");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	justify-content: center;
	align-items: center;
	@media screen and (max-width: 1024px) {
		background: url('../img/assets/website-mountain_mobile-tablet.jpg');
		min-height: 160vh;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		align-items: flex-start;
	}
	@media screen and (max-width: 767px) {
		min-height: 150vh;
		background: url('../img/assets/website-mountain_mobile-tablet.jpg');
		background-position: right;
		background-size: cover;
		background-repeat: no-repeat;
	}
}

.main__strat-wrapper {
	width: 80%;
	display: flex;
	justify-content: flex-end;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
	@media screen and (max-width: 1024px){
		justify-content: center;
	}
	@media screen and (max-width: 767px) {
		width: 100%;
		align-self: flex-start;
		background: white;
		justify-content: center;
	}
}

.main__strat-box {
	width: 600px;
	height: 500px;
	background: white;
	padding: 6rem 4rem 6rem 4rem;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	@media screen and (max-width: 1024px) {
		margin-top: 3rem;
	}
	@media screen and (max-width: 767px) {
		width: 450px;
		height: 450px;
		padding: 6rem 2rem 6rem 2rem;
		margin-top: 0;
	}
}


.main__strat-tag {
	width: 100%;
}

.main__strat-tag h4 {
	color: #939598;
	text-transform: uppercase;
	font-family: 'Poppins', sans-serif;
	font-weight: 300;
	letter-spacing: 0.05rem;
	font-size: 0.75rem;
}

.main__strat-tag h4 span.title__color {
	color: black;
	padding-left: 1rem;
}

.main__strat-title {
	padding-top: 1rem;
	padding-bottom: 1.25rem;
}

.main__strat-title h1 {
	font-family: "Poppins", sans-serif;
	font-weight: 500;
	color: #2a4549;
	font-size: 2.5rem;
	line-height: 3rem;
	@media screen and (max-width: 1024px) {
		font-size: 2rem;
		line-height: 2rem;
	}
}

.main__strat-article p {
	width: 90%;
	font-family: "Neue Haas Grotesk Text Pro", sans-serif;
	font-size: 1.25rem;
	line-height: 2rem;
	color: #2a4549;
	@media screen and (max-width: 1024px) {
		font-size: 1rem;
		line-height: 1.5rem;
		width: 100%;
	}
}

.main__strat-article p.statement__bold {
	font-weight: 600;
}

.main__strat-button {
	padding-top: 2.5rem;
}

.main__strat-button a.article-button {
	font-family: "neue-haas-grotesk-text-pro", sans-serif;
	font-size: 0.65rem;
	font-weight: bold;
	color: #2a4549;
	text-decoration: none;
	text-transform: uppercase;
	border: 1px solid #2a4549;
	padding: 0.5rem;
}

// unassigned section two
.logo__grid {
	width: 100%;
	height: 100%;
	background: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 12rem;
	padding-bottom: 6rem;
	@media screen and (max-width: 1024px) {
		padding: 4rem 0rem 4rem 0rem;
	}
}

.logo__title {
	width: 80%;
	align-self: flex-start;
	justify-self: flex-start;
	margin: 0 auto;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
	@media screen and (max-width: 1024px) {
		padding-left: 0;
		width: 80%;
		padding-bottom: 3rem;
		text-align: left;
	}
}

.logo__title h1 {
	width: 100%;
	font-family: "Poppins", sans-serif;
	color: black;
	font-size: 2.5rem;
	font-weight: 500;
	@media (min-width: 320px) and (max-width: 599px) {
		font-size: 1.25rem;
		margin-bottom: 1rem;
		width: 70%;
	}
	@media (min-width: 599px) and (max-width: 1024px) {
		font-size: 1.25rem;
		margin-bottom: 1rem;
		width: 70%;
	}
	@media only screen and (min-width: 1024px) {
		font-size: 3.15vw;
		width: 70%;
	}
	@media only screen and (min-width: 1278px) {
		font-size: 2.75vw;
		width: 80%;
	}
	@media only screen and (min-width: 1400px) {
		font-size: 2.25vw;
		width: 100%;
	}
	@media only screen and (min-width: 1920px) {
		font-size: 2vw;
		width: 100%;
	}
}

.logo__grid .logo__title h1 br {
	@media only screen and (max-width: 1320px) {
		display: none;
	}
}

.logo__container {
	width: 88.5%;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: 100px;
	// Auto-rows to adjust grid height
	grid-auto-rows: 110px;
	grid-auto-columns: 110px;
	column-gap: 5rem;
	grid-template-areas: "br1 br2 br3 br4" "lg1 lg2 lg3 lg4" "br5 br6 br7 br8" "lg5 lg6 lg7 lg8" "br9 br10 br11 br12"
		"lg9 lg10 lg11 lg12" "br13 br14 br15 br16" "lg13 lg14 lg15 lg16" "br17 br18 br19 br20";
	padding: 1rem;
	justify-content: center;
	align-items: center;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
	@media screen and (max-width: 1024px) {
		display: none;
	}
}

span.border.br1,
span.border.br2,
span.border.br3,
span.border.br4,
span.border.br5,
span.border.br6,
span.border.br7,
span.border.br8,
span.border.br9,
span.border.br10,
span.border.br11,
span.border.br12,
span.border.br13,
span.border.br14,
span.border.br15,
span.border.br16,
span.border.br17,
span.border.br18,
span.border.br19,
span.border.br20 {
	align-self: center;
	justify-self: center;
	width: 80%;
	height: 2px;
	background: lightgrey;
	margin: 5rem;
	@media screen and (max-width: 1024px) {
		display: none;
	}
}

span.border.br1 {
	grid-area: br1;
}

span.border.br2 {
	grid-area: br2;
}

span.border.br3 {
	grid-area: br3;
}

span.border.br4 {
	grid-area: br4;
}

span.border.br5 {
	grid-area: br5;
}

span.border.br6 {
	grid-area: br6;
}

span.border.br7 {
	grid-area: br7;
}

span.border.br8 {
	grid-area: br8;
}

span.border.br9 {
	grid-area: br9;
}

span.border.br10 {
	grid-area: br10;
}

span.border.br11 {
	grid-area: br11;
}

span.border.br12 {
	grid-area: br12;
}

span.border.br13 {
	grid-area: br13;
}

span.border.br14 {
	grid-area: br14;
}

span.border.br15 {
	grid-area: br15;
}

span.border.br16 {
	grid-area: br16;
}

span.border.br17 {
	grid-area: br17;
}

span.border.br18 {
	grid-area: br18;
}

span.border.br19 {
	grid-area: br19;
}

span.border.br20 {
	grid-area: br20;
}

.logo__container > img {
	@media screen and (max-width: 1024px) {
		scroll-snap-align: start;
	}
}

.logo__container img {
	align-self: center;
	justify-self: center;
}

.logo__container img.lg1 {
	width: 55%;
	grid-area: lg1;
	@media (min-width: 320px) and (max-width: 599px) {
		width: 20rem;
	}
	@media only screen and (min-width: 620px) {
		width: 65%;
	}
	@media only screen and (min-width: 768px) {
		width: 55%;
	}
	@media only screen and (min-width: 920px) and (max-width: 1024px) {
		width: 50%;
	}
}

.logo__container img.lg2 {
	width: 65%;
	grid-area: lg2;
	@media (min-width: 320px) and (max-width: 599px) {
		width: 20rem;
	}
	@media only screen and (min-width: 620px) {
		width: 65%;
	}
	@media only screen and (min-width: 768px) {
		width: 60%;
	}
	@media only screen and (min-width: 920px) and (max-width: 1024px) {
		width: 50%;
	}
}

.logo__container img.lg3 {
	width: 60%;
	grid-area: lg3;
	@media (min-width: 320px) and (max-width: 599px) {
		width: 20rem;
	}
	@media only screen and (min-width: 620px) {
		width: 65%;
	}
	@media only screen and (min-width: 768px) {
		width: 50%;
	}
	@media only screen and (min-width: 920px) and (max-width: 1024px) {
		width: 50%;
	}
}

.logo__container img.lg4 {
	width: 55%;
	grid-area: lg4;
	@media (min-width: 320px) and (max-width: 599px) {
		width: 20rem;
	}
	@media only screen and (min-width: 620px) {
		width: 65%;
	}
	@media only screen and (min-width: 768px) {
		width: 55%;
	}
	@media only screen and (min-width: 920px) and (max-width: 1024px) {
		width: 50%;
	}
}

.logo__container img.lg5 {
	width: 50%;
	grid-area: lg5;
	@media (min-width: 320px) and (max-width: 599px) {
		width: 20rem;
	}
	@media only screen and (min-width: 620px) {
		width: 65%;
	}
	@media only screen and (min-width: 768px) {
		width: 55%;
	}
	@media only screen and (min-width: 920px) and (max-width: 1024px) {
		width: 50%;
	}
}

.logo__container img.lg6 {
	width: 60%;
	grid-area: lg6;
	@media (min-width: 320px) and (max-width: 599px) {
		width: 20rem;
	}
	@media only screen and (min-width: 620px) {
		width: 65%;
	}
	@media only screen and (min-width: 768px) {
		width: 55%;
	}
	@media only screen and (min-width: 920px) and (max-width: 1024px) {
		width: 50%;
	}
}

.logo__container img.lg7 {
	width: 50%;
	grid-area: lg7;
	@media (min-width: 320px) and (max-width: 599px) {
		width: 20rem;
	}
	@media only screen and (min-width: 620px) {
		width: 65%;
	}
	@media only screen and (min-width: 768px) {
		width: 50%;
	}
	@media only screen and (min-width: 920px) and (max-width: 1024px) {
		width: 50%;
	}
}

.logo__container img.lg8 {
	width: 60%;
	grid-area: lg8;
	@media (min-width: 320px) and (max-width: 599px) {
		width: 20rem;
	}
	@media only screen and (min-width: 620px) {
		width: 65%;
	}
	@media only screen and (min-width: 768px) {
		width: 55%;
	}
	@media only screen and (min-width: 920px) and (max-width: 1024px) {
		width: 50%;
	}
}

.logo__container img.lg9 {
	width: 65%;
	grid-area: lg9;
	@media (min-width: 320px) and (max-width: 599px) {
		width: 20rem;
	}
	@media only screen and (min-width: 620px) {
		width: 65%;
	}
	@media only screen and (min-width: 768px) {
		width: 55%;
	}
	@media only screen and (min-width: 920px) and (max-width: 1024px) {
		width: 50%;
	}
}

.logo__container img.lg10 {
	width: 50%;
	grid-area: lg10;
	@media (min-width: 320px) and (max-width: 599px) {
		width: 20rem;
	}
	@media only screen and (min-width: 620px) {
		width: 65%;
	}
	@media only screen and (min-width: 768px) {
		width: 45%;
	}
	@media only screen and (min-width: 920px) and (max-width: 1024px) {
		width: 50%;
	}
}

.logo__container img.lg11 {
	width: 65%;
	grid-area: lg11;
	@media (min-width: 320px) and (max-width: 599px) {
		width: 20rem;
	}
	@media only screen and (min-width: 620px) {
		width: 65%;
	}
	@media only screen and (min-width: 768px) {
		width: 55%;
	}
	@media only screen and (min-width: 920px) and (max-width: 1024px) {
		width: 50%;
	}
}

.logo__container img.lg12 {
	width: 40%;
	grid-area: lg12;
	@media (min-width: 320px) and (max-width: 599px) {
		width: 20rem;
	}
	@media only screen and (min-width: 620px) {
		width: 65%;
	}
	@media only screen and (min-width: 768px) {
		width: 40%;
	}
	@media only screen and (min-width: 920px) and (max-width: 1024px) {
		width: 50%;
	}
}

.logo__container img.lg13 {
	width: 65%;
	grid-area: lg13;
	@media (min-width: 320px) and (max-width: 599px) {
		width: 20rem;
	}
	@media only screen and (min-width: 620px) {
		width: 65%;
	}
	@media only screen and (min-width: 768px) {
		width: 50%;
	}
	@media only screen and (min-width: 920px) and (max-width: 1024px) {
		width: 50%;
	}
}

.logo__container img.lg14 {
	width: 65%;
	grid-area: lg14;
	@media (min-width: 320px) and (max-width: 599px) {
		width: 20rem;
	}
	@media only screen and (min-width: 620px) {
		width: 65%;
	}
	@media only screen and (min-width: 768px) {
		width: 55%;
	}
	@media only screen and (min-width: 920px) and (max-width: 1024px) {
		width: 50%;
	}
}

.logo__container img.lg15 {
	width: 60%;
	grid-area: lg15;
	@media (min-width: 320px) and (max-width: 599px) {
		width: 20rem;
	}
	@media only screen and (min-width: 620px) {
		width: 65%;
	}
	@media only screen and (min-width: 768px) {
		width: 55%;
	}
	@media only screen and (min-width: 920px) and (max-width: 1024px) {
		width: 50%;
	}
}

.logo__container img.lg16 {
	max-width: 50%;
	grid-area: lg16;
	@media (min-width: 320px) and (max-width: 599px) {
		width: 20rem;
	}
	@media only screen and (min-width: 620px) {
		width: 65%;
	}
	@media only screen and (min-width: 768px) {
		width: 45%;
	}
	@media only screen and (min-width: 920px) and (max-width: 1024px) {
		width: 50%;
	}
}

//MOBILE CAROUSEL

.customer-logos {
	height: 100%;
	transition: ease-in-out all 0.5s;
	-webkit-transition: ease-in-out all 0.5s;
	-moz-transition: ease-in-out all 0.5s;
	-o-transition: ease-in-out all 0.5s;
	@media screen and (min-width: 1024px) {
		display: none;
	}
}

.carousel__wrapper {
	width: 100%;
	background: white;
	display: flex;
	justify-content: center;
	padding-bottom: 5rem;
	@media screen and (min-width: 1024px) {
		display: none;
	}
}

.slider {
	width: 100% !important;
	background: white !important;
	@media screen and (min-width: 1024px) {
		display: none;
	}
}

// panel test end
.cookies__container {
	width: 35%;
	padding: 2em;
	border-radius: 10px;
	background: rgb(255, 255, 255);
	display: flex;
	align-items: center;
	position: fixed;
	right: 1em;
	bottom: 1em;
	z-index: 5000;
}

@media only screen and (max-width: 768px) {
	.cookies__container {
		width: 100%;
		padding: 2em;
		border-radius: 0px;
		right: 0;
		bottom: 0;
	}
}

.cookies__wrapper {
	width: 100%;
}

.cookies__content {
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

@media only screen and (max-width: 440px) {
	.cookies__content {
		flex-direction: column;
		justify-items: flex-start;
		align-items: flex-start;
	}
}

.cookies__content h3 {
	font-family: 'Poppins', sans-serif;
	color: #3ea89a;
	font-size: 0.75em;
	font-weight: 300;
	margin-right: 2em;
}

@media only screen and (max-width: 768px) {
	.cookies__content h3 {
		margin-bottom: 1em;
	}
}

@media only screen and (max-width: 768px) {
	.cookies__container {
		font-size: 0.75em;
	}
}

.cookies__content h3 a {
	color: #3ea89a;
}

.cookies__content h3 a:hover {
	color: #2a454a;
}

.cookies__content .cookies__button {
	width: 30%;
	text-align: center;
	font-family: "Poppins", sans-serif;
	font-size: 0.75em;
	padding: 0.5em;
	padding-left: 2em;
	padding-right: 2em;
	background: #3ea89a;
	border-radius: 5px;
	color: white;
	cursor: pointer;

}

@media only screen and (max-width: 768px) {
	.cookies__content .cookies__button {
		font-size: 0.75em;
		padding: 0.5em;
	}
}

.cookies__content .cookies__button:hover {
	background: #2a454a;
	color: white;
}
